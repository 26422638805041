import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailSubscriberComponent } from './email-subscriber.component';
import { SharedModule } from '@shared/shared.module';
import { EmailSubscriberDialogComponent } from './email-subscriber-dialog.component';

@NgModule({
  declarations: [ EmailSubscriberComponent, EmailSubscriberDialogComponent],
  imports: [CommonModule, SharedModule],
  exports: [ EmailSubscriberComponent, EmailSubscriberDialogComponent]
})
export class EmailSubscriberModule {}
