import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpandableBlockComponent } from './expandable-block.component';



@NgModule({
  declarations: [ ExpandableBlockComponent],
  imports: [
    CommonModule
  ],
  exports: [ ExpandableBlockComponent]
})
export class ExpandableBlockModule { }
