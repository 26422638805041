import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class OverlayService {
  private showSubject$ = new Subject<boolean>();

  get show$(): Observable<boolean> {
    return this.showSubject$.asObservable();
  }

  showOverlay(): void {
    this.showSubject$.next(true);
  }

  hideOverlay(): void {
    this.showSubject$.next(false);
  }
}
