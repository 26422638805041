<div class="container-fluid bg">
  <header class="py-3">
    <gc-accept-cookie
      *ngIf="showcookieAlert"
      (accepted)="saveCookieAcceptance()"
    ></gc-accept-cookie>
    <img
      [routerLink]="['/']"
      src="./assets/logo.svg"
      alt="getgreatcareers"
    />
  </header>
</div>

<router-outlet></router-outlet>

<div class="overlay" *ngIf="showOverlay"></div>

<gc-footer class="mt-auto"></gc-footer>



