<div class="d-flex align-items-center content flex-column py-4 mt-5" *ngIf="!loading">
    <span *ngIf="unsubscribed">ⓘ You have successfully unsubscribed.</span>
    <span *ngIf="!unsubscribed">Email not found. Please contact <a href="mailto:contact@getgreatcareers.com">contact&#64;getgreatcareers.com</a> for support.</span>
    <div
      class="mt-2 note"
      *ngIf="this.unsubscribed"
    >
      You will no longer receive emails from us. If you wish to re-subscribe, <a (click)="openSubscribeDialog($event)">click here</a>
    </div>
</div>

<div
  class="page-loader"
  *ngIf="loading"
></div>
