<ng-container *ngIf="loading">
  <div class="d-flex header-skeleton">
    <ngx-skeleton-loader
      count="1"
      appearance="circle"
      [theme]="{
        width: '90px',
        height: '90px',
        'border-radius': '10px'
      }"
    >
    </ngx-skeleton-loader>

    <ngx-skeleton-loader
      count="2"
      class="mt-2 ml-3"
      [theme]="{
      width: '400px',
      'border-radius': '0',
      height: '25px',
      'margin-bottom': '20px'
    }"></ngx-skeleton-loader>

  </div>

  <div class="content-skeleton">
    <ngx-skeleton-loader
      count="2"
      [theme]="{
      width: '400px',
      'border-radius': '0',
      height: '15px',
      'margin-bottom': '10px'
    }"
    ></ngx-skeleton-loader>
    <br>
    <ngx-skeleton-loader
      count="8"
      [theme]="{
      width: '700px',
      'border-radius': '0',
      height: '15px',
      'margin-bottom': '10px'
    }"
    ></ngx-skeleton-loader>

  </div>

</ng-container>

<ng-container *ngIf="!loading">
<div class="top">
  <div
    class="line"
    [style.background-color]="job?.brandColor"
  ></div>
  <lib-header
    [logo]="job?.logoUrl"
    [title]="job?.title"
    [companyName]="job?.campaign?.title"
    [location]="job?.address"
    [employment]="job?.jobType"
  ></lib-header>
  <div
    class="close-icon"
    (click)="backClick.emit()"
  ><i class="icon-cross"></i>
  </div>

  <lib-card class="apply" *ngIf="!job?.isQuickApply && !job?.isFrontRunner">
    <ng-container [ngTemplateOutlet]="applyButton"></ng-container>
  </lib-card>

  <lib-quick-apply
    class="d-block"
    *ngIf="job?.isQuickApply || job?.isFrontRunner"
  >
    <span
      *ngIf="job?.isQuickApply"
      class="front-runnter d-flex align-items-center flex-column flex-sm-row"
      text
    >Finish this application in less than 5 minutes!
      <ng-container [ngTemplateOutlet]="applyButton"></ng-container>
    </span>
    <span
      *ngIf="job?.isFrontRunner"
      class="front-runnter d-flex align-items-center flex-column flex-sm-row"
      text
    >Be a Front runner! Less than 10 people have applied to this job so far.
      <ng-container [ngTemplateOutlet]="applyButton"></ng-container>
    </span>
  </lib-quick-apply>

  <ng-template #applyButton>
    <span class="btn-container ml-sm-auto mt-2 mt-sm-0">
      <lib-button
        *ngIf="!submitted"
        (libClick)="goToJob()"
        class="submit-btn  ml-sm-3"
        [form]="'questions-form'"
      >{{applyButtonText}}</lib-button>
    </span>
  </ng-template>
</div>

<div
  class="description"
  *ngIf="!message"
>
<lib-card *ngIf="job?.description">
  <div
    class="text"
    [innerHtml]="job?.description"
  ></div>
</lib-card>
<lib-card id="questions">
  <lib-questions
    *ngIf="!message && questions"
    [applyButtonText]="applyButtonText"
    [questions]="questions"
    [loading]="loading"
    (formSubmitted)="submit($event)"
  >

  </lib-questions>
</lib-card>
</div>

<lib-card>
  <ng-container *ngIf="message && isMessageTemplate">
    <ng-template
      [ngTemplateOutlet]="message"
    ></ng-template>
  </ng-container>

  <div
    class="d-flex justify-content-center p-4 message"
    *ngIf="message && !isMessageTemplate"
    [innerHtml]="message"
  ></div>

</lib-card>

<lib-card class="mt-auto d-flex flex-column align-items-stretch">
  <div class="d-flex align-items-center">
    <span
      class="link"
      (click)="backClick.emit()"
    >Back To Other Jobs</span>
  </div>
</lib-card>
</ng-container>
