import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'lib-control-errors',
  templateUrl: './control-errors.component.html',
  styleUrls: ['./control-errors.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ControlErrorsComponent {
  innerText: string;
  hide = true;

  @Input() set text(value) {
    if (value !== this.innerText) {
      this.innerText = value;
      this.hide = !value;
      this.cdr.detectChanges();
    }
  }

  constructor(private cdr: ChangeDetectorRef) { }

}
