import {
  Directive,
  Self,
  OnInit,
  Inject,
  ComponentRef,
  ViewContainerRef,
  ComponentFactoryResolver,
  OnDestroy,
  Input,
  Optional, inject, DestroyRef
} from '@angular/core';
import { ControlContainer, NgControl } from '@angular/forms';
import { FORM_ERRORS } from './form-errors';
import { ControlErrorsComponent } from './control-errors.component';
import { merge, EMPTY } from 'rxjs';
import { FormSubmitDirective } from './form-submit/form-submit.directive';
import { debounceTime } from 'rxjs/operators';
import { ControlErrorContainerDirective } from './control-errors-container.directive';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  selector: '[formControl], [formControlName]'
})
export class ControlErrorsDirective implements OnInit, OnDestroy {
  private destroyRef = inject(DestroyRef);

  @Input() messagePrefix: string;
  ref: ComponentRef<ControlErrorsComponent>;
  containerRef: ComponentRef<ControlErrorsComponent>;
  submit$: any;
  container: ViewContainerRef;

  constructor(
    @Self() private control: NgControl,
    @Inject(FORM_ERRORS) private errors,
    @Optional() private form: FormSubmitDirective,
    @Optional() private arrayControl: ControlContainer,
    @Optional() private controlErrorContainer: ControlErrorContainerDirective,
    private resolver: ComponentFactoryResolver,
    private vcr: ViewContainerRef
  ) {
    this.submit$ = this.form ? this.form.submit$ : EMPTY;
  }

  ngOnInit() {
    merge(this.control.valueChanges, this.control.statusChanges, this.submit$)
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(0))
      .subscribe(() => {
        const controlErrors = this.controlErrorContainer
          ? this.arrayControl.control.errors
          : this.control.errors;

        if (controlErrors) {
          const firstKey = Object.keys(controlErrors)[0];
          const getError = this.errors[
            this.messagePrefix ? `${firstKey}-${this.messagePrefix}` : firstKey
          ];
          const text = getError(controlErrors[firstKey]);
          this.setError(text);
        } else if (this.ref || this.controlErrorContainer) {
          this.setError(null);
        }
      });
  }

  ngOnDestroy() {}

  setError(text: string) {
    this.container = this.controlErrorContainer
      ? this.controlErrorContainer.vcr
      : this.vcr;

    this.containerRef = this.controlErrorContainer
      ? this.controlErrorContainer.ref
      : this.ref;
    const factory = this.resolver.resolveComponentFactory(
      ControlErrorsComponent
    );

    if (!this.ref && !this.controlErrorContainer) {
      this.ref = this.container.createComponent(factory);
    }

    if (this.controlErrorContainer) {
      this.container.clear();
      this.ref = this.container.createComponent(factory);
    }

    this.ref.instance.text = text;
  }
}
