import {
  Component,
  OnInit,
  Input,
  HostBinding,
  Output,
  EventEmitter,
  OnDestroy,
  inject,
  DestroyRef
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CookieService } from 'ngx-cookie-service';
import { EMAIL_COOKIE_KEY, EmailActivationType } from '@shared/consts';
import { SubscriptionProvider } from '@core/data-providers';
import { ReCaptchaV3Service } from 'ng-recaptcha-2';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'gc-email-subscriber',
  templateUrl: './email-subscriber.component.html',
  styleUrls: ['./email-subscriber.component.less']
})
export class EmailSubscriberComponent implements OnInit, OnDestroy {
  private destroyRef = inject(DestroyRef);

  @Input() keyword: string;
  @Input() location: string;
  @Input() formClass: string;
  @Input() hostStyle: string;
  @Input() activationType: EmailActivationType;
  @Output() subscribed = new EventEmitter();

  @HostBinding('style')
  get style() {
    return !this.emailAlertCookie ? this.hostStyle : '';
  }

  emailAlertCookie: boolean;
  loading: boolean;

  constructor(
    private subscriber: SubscriptionProvider,
    private snackBar: MatSnackBar,
    private cookieService: CookieService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {}

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.emailAlertCookie = Boolean(this.cookieService.get(EMAIL_COOKIE_KEY));
  }

  emailSubmitted({ email, location, keyword }): void {
    this.recaptchaV3Service
      .execute('emailSubscription')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(recaptchaToken =>
        this.subscribeToReceiveEmails({
          email,
          location,
          keyword,
          recaptchaToken
        })
      );
  }

  subscribeToReceiveEmails({ email, location, keyword, recaptchaToken }): void {
    this.loading = true;
    this.subscriber
      .subscribeToReceiveEmails(
        email,
        location,
        keyword,
        this.activationType || EmailActivationType.search,
        recaptchaToken
      )
      .subscribe(
        response => {
          this.cookieService.set(EMAIL_COOKIE_KEY, 'true');
          this.emailAlertCookie = true;
          this.snackBar.open('You have been successfully subscribed!');
          this.subscribed.emit(true);
        },
        ({ error }) => {
          this.snackBar.open(
            error.errors || 'Sorry, something went wrong.',
            null,
            {
              panelClass: ['notification-error']
            }
          );
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  }
}
