import { JobsStateModel } from './jobs-state.model';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { JobsProvider } from 'src/core/data-providers';
import { LoadJobs, LoadSeletedJob, SeletJob, SubmitAnswers } from './actions';
import { tap } from 'rxjs/operators';

@State<JobsStateModel>({
  name: 'jobs',
  defaults: {
    jobs: [],
    jobsPerPage: 15,
    totalJobsCount: 0,
    page: 0
  }
})
@Injectable()
export class JobsState {
  @Selector()
  static jobs(state: JobsStateModel) {
    return state.jobs;
  }

  @Selector()
  static pipeline(state: JobsStateModel) {
    return state.pipeline;
  }

  @Selector()
  static clickoutJob(state: JobsStateModel) {
    return state.clickoutJob;
  }

  @Selector()
  static selectedJob(state: JobsStateModel) {
    return state.selectedJob;
  }

  @Selector()
  static pixelScriptSource(state: JobsStateModel) {
    return state.pixelScriptSource;
  }

  @Selector()
  static isNotQualifiedApply(state: JobsStateModel) {
    return state.isNotQualified;
  }

  @Selector()
  static redirectUrl(state: JobsStateModel) {
    return state.redirectUrl;
  }

  @Selector()
  static pages(state: JobsStateModel) {
    return {
      total: state.totalJobsCount,
      currentPage: state.page,
      perPage: state.jobsPerPage
    };
  }

  constructor(private jobsProvider: JobsProvider) {}

  @Action(LoadJobs, { cancelUncompleted: true })
  loadJobs(
    ctx: StateContext<JobsStateModel>,
    { searchTerm, location, page, params }: LoadJobs
  ) {
    page = page >= 1 ? page : 1;
    return this.jobsProvider
      .search(
        this.safeString(searchTerm),
        this.safeString(location),
        page - 1,
        params
      )
      .pipe(
        tap(response =>
          ctx.patchState({
            jobs: response.jobs,
            totalJobsCount: response.jobsCount,
            pipeline: response.pipeline,
            clickoutJob: response.clickoutJob,
            page
          })
        )
      );
  }

  @Action(LoadSeletedJob, { cancelUncompleted: true })
  loadSelectedJob(
    ctx: StateContext<JobsStateModel>,
    { id, jobReference, params }: LoadSeletedJob
  ) {
    const job = ctx.getState().jobs.find(job => job.id === jobReference) || {};

    return this.jobsProvider.getJobDetails(id, params).pipe(
      tap(response => {
        ctx.patchState({
          selectedJob: { ...job, ...response }
        });
      })
    );
  }

  @Action(SeletJob, { cancelUncompleted: true })
  selecteJob(
    ctx: StateContext<JobsStateModel>,
    { id }: SeletJob
  ) {

    const job = ctx.getState().jobs.find(job => job.id === id);
    
    ctx.patchState({
      selectedJob: { ...job }
    })
  }

  @Action(SubmitAnswers, { cancelUncompleted: true })
  submitAnswers(
    ctx: StateContext<JobsStateModel>,
    { answers, id, settings }: SubmitAnswers
  ) {
    return this.jobsProvider.submitAnswers(id, answers, settings).pipe(
      tap(response => {
        const selectedJob = ctx.getState().selectedJob;


        ctx.patchState({
          pixelScriptSource: response.pixelJs,
          isNotQualified: response.isNotQualified,
          redirectUrl:
            !response.isNotQualified && response.redirectUrl
              ? response.redirectUrl
              : undefined,
          selectedJob: response.questions
            ? {
                ...selectedJob,
                secondPageId: response.id,
                questions: response.questions
              }
            : selectedJob
        });
      })
    );
  }

  private safeString(text: string): string {
    return text == null ? '' : text;
  }
}
