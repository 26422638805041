import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'lib-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.less']
})
export class ButtonComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() loading: boolean;
  @Input() form: string;
  @Input() set color(color) {
    this.className = {
      accent: 'btn-accent',
      primary: 'btn-primary'
    }[color];

    if (!this.className) {
      this.btnColor = color;
      this.className = 'btn-custom';
    }
  }

  @Output() libClick = new EventEmitter();

  className: string;
  btnColor: string;

  constructor() { }

  ngOnInit(): void {
  }

}
