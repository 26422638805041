import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy
} from '@angular/core';
import { EmployerPipelineViewModel } from '@core/view-models';

@Component({
  selector: 'gc-employer-pipelene',
  templateUrl: './employer-pipelene.component.html',
  styleUrls: ['./employer-pipelene.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployerPipeleneComponent implements OnInit {
  @Input() pipeline: EmployerPipelineViewModel;
  @Input() showRedirectToEmloyerPage: boolean;
  @Input() isPipelineEnabled: boolean;

  constructor() {}

  ngOnInit(): void {}
}
