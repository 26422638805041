import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScriptService {
  insertScript(url: string): HTMLScriptElement {
    if (!url) {
      return;
    }

    const script = document.createElement('script');
    script.src = url;
    script.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(script);

    return script;
  }
}
