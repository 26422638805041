<div class="container-fluid bg pb-2">
  <gc-employer-pipelene
    [pipeline]="employerPipeline$ | async"
    [isPipelineEnabled]="isPipelineEnabled$ | async"
    [showRedirectToEmloyerPage]="showRedirectToEmloyerPage"
  ></gc-employer-pipelene>
  <gc-search-box
    [(showLocationTooltip)]="showLocationTooltip"
    [locationLoading]="!locationLoaded"
    [initialLocation]="location"
    [initialKeyword]="keyword"
    [loading]="loading"
    (searchClick)="search($event)"
  ></gc-search-box>
</div>

<div
  class="container-fluid d-flex flex-column flex-md-row pt-3"
  [class.selected]="jobSelected"
>
  <gc-jobs-list
    class="mr-sm-4"
    [loading]="loading || !locationLoaded"
    [jobs]="jobs$ | async"
    [pagesInfo]="pagesInfo$ | async"
    [location]='location'
    [keyword]='keyword'
    [jobSelectedId]="jobSelected?.id"
    (pageChange)="pageChanged($event)"
    (jobClicked)="selectJob($event)"

  ></gc-jobs-list>

  <!-- capturing emails feature is temporary hidden-->
  <!-- <gc-email-subscriber
    *ngIf="!loading && (hasJobs$ | async) && !jobSelected"
    [keyword]="keyword"
    [location]="location"
    [hostStyle]="'max-width: 570px;flex: 1;'"
    class="order-first order-md-last mt-n3 mt-sm-0"
    [formClass]="'mb-2 mt-sm-4 mx-n3 mx-sm-0'"
  ></gc-email-subscriber> -->

  <ng-container *ngIf="jobSelected">
    <gc-job-view
      class="ml-md-auto mr-md-auto mb-2"
      [job]="jobSelected"
      [loading]="loading"
      [questions]="questions$ | async"
      [submitted]="submitted"
      [applyButtonText]="jobSelected?.applyBtnLabel || 'Apply'"
      [message]="message"
      (formSubmit)="formSubmitHandler($event)"
      (backClick)="jobSelected = null"
    ></gc-job-view>
  </ng-container>
</div>

<ng-template #clickoutJobTemplate let-data>
  <div mat-dialog-content>
    <div class="frame-wrap">
      <iframe
        class="job-frame"
        [src]="url"
      ></iframe>
    </div>

  </div>
  <div class="mt-auto" mat-dialog-actions [align]="'center'">
    <lib-button
      [mat-dialog-close]="true"
      (libClick)="goToJob(data.url)"
    >Continue</lib-button>
  </div>

</ng-template>
