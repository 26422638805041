<lib-card>
  <div class="text-container">
    <div class="clock">
      <i class="icon-clock"></i>
    </div>
    <div class="text">
      <ng-content select="[text]"></ng-content>
    </div>
  </div>
  <ng-content select="[footer]"></ng-content>
</lib-card>
