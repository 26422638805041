import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { OverlayService } from './overlay.service';

@Injectable({
  providedIn: 'root'
})
export class BoostGuard  {

  constructor(private httpClient: HttpClient, private overlay: OverlayService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.overlay.showOverlay();
    return new Promise<boolean>((resolve, reject) => {
      this.getBoostUrl(route.params).subscribe({
        next: (url) => {
          window.location = url;
          resolve(false);
        },
        error: () => {
          resolve(true);
          this.overlay.hideOverlay();
        }
      });
    });
  }

  private getBoostUrl(params): Observable<any> {
    return this.httpClient.get('/api/boost-clickout', { params }).pipe(catchError((err: HttpErrorResponse) => {
      if (err.status === 302) {
        return of(err.error.redirect_url);
      }

      return throwError(err);
    }));
  }
}
