import { timer, Observable, of, pipe } from 'rxjs';
import { mapTo, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { IJob, SearchResult, PipelineData } from '../models';
import {
  IJobViewModel,
  EmployerPipelineViewModel,
  HostedJobViewModel,
  Question
} from '../view-models';
import { format, parseISO } from 'date-fns';
import { HttpClient } from '@angular/common/http';
import { Params } from '@angular/router';
import {
  QuestionControlTypeEnum,
  QuestionTypeEnum
} from 'src/common/models/job.model';

const controlTypes = {
  [QuestionTypeEnum.check_boxes]: QuestionControlTypeEnum.checkbox,
  [QuestionTypeEnum.dropdown]: QuestionControlTypeEnum.dropdown,
  [QuestionTypeEnum.email]: QuestionControlTypeEnum.textbox,
  [QuestionTypeEnum.file]: QuestionControlTypeEnum.upload,
  [QuestionTypeEnum.phone]: QuestionControlTypeEnum.phone,
  [QuestionTypeEnum.radio_buttons]: QuestionControlTypeEnum.radio,
  [QuestionTypeEnum.resume]: QuestionControlTypeEnum.upload,
  [QuestionTypeEnum.string]: QuestionControlTypeEnum.textbox,
  [QuestionTypeEnum.text]: QuestionControlTypeEnum.textarea,
  [QuestionTypeEnum.user_name]: QuestionControlTypeEnum.textbox,
  [QuestionTypeEnum.zip]: QuestionControlTypeEnum.number,
  [QuestionTypeEnum.location_confirmation]: QuestionControlTypeEnum.dropdown,
  [QuestionTypeEnum.number]: QuestionControlTypeEnum.number,
  [QuestionTypeEnum.consent]: QuestionControlTypeEnum.consent,
  [QuestionTypeEnum.date]: QuestionControlTypeEnum.date,
  [QuestionTypeEnum.info]: QuestionControlTypeEnum.info,
  [QuestionTypeEnum.first_name]: QuestionControlTypeEnum.textbox,
  [QuestionTypeEnum.last_name]: QuestionControlTypeEnum.textbox
};

@Injectable()
export class JobsProvider {
  private readonly tags = {
    POSTED_YESTERDAY_TAG: 0,
    POSTED_TODAY_TAG: 1,
    QUICK_APPLY_TAG: 2,
    FIRST_APPLICANT: 3
  };

  constructor(private httpClient: HttpClient) {}

  search(searchTerm: string, location: string, page = 0, params = {}) {
    return this.httpClient
      .get<SearchResult>(`/job_search/search`, {
        params: {
          keyword: searchTerm,
          location,
          page: page.toString(),
          ggc_ui: 'true',
          ...params
        }
      })
      .pipe(
        map(response => {
          return {
            jobsCount: response.jobsCount,
            locationIgnored: response.locationIgnored,
            jobs: response.jobs.map(job => this.mapJob(job)),
            clickoutJob:
              response.clickoutJob && response.clickoutJob.jobAttributes,
            pipeline:
              response.employerPipeline &&
              this.mapPipeline(response.employerPipeline.piplelineData)
          };
        })
      );
  }

  private mapPipeline({
    isPipelineEnabled,
    employerJobsCount,
    employerLogo,
    ...rest
  }: PipelineData): EmployerPipelineViewModel {
    return {
      enabled: isPipelineEnabled,
      logo: employerLogo,
      jobsCount: employerJobsCount,
      ...rest
    };
  }

  private mapJob(job: IJob): IJobViewModel {
    const isFrontRunner =
      job.metaTags && job.metaTags.includes(this.tags.FIRST_APPLICANT);
    const isQuickApply =
      job.metaTags && job.metaTags.includes(this.tags.QUICK_APPLY_TAG);
    const isPostedToday =
      job.metaTags && job.metaTags.includes(this.tags.POSTED_TODAY_TAG);
    const isPostedYesterday =
      job.metaTags && job.metaTags.includes(this.tags.POSTED_YESTERDAY_TAG);

    return {
      title: job.title,
      address: `${job.location.city || ''}${job.location.city ? ', ' : ''}${
        job.location.state
      } ${job.location.zip || ''}`,
      id: job.jobId,
      description: job.body,
      url: job.url,
      isFrontRunner,
      isQuickApply,
      isPostedToday,
      isPostedYesterday,
      employerId: null,
      posted: isPostedToday
        ? 'posted today'
        : isPostedYesterday
        ? 'posted yesterday'
        : ` posted ${format(parseISO(job.postedAt), 'P')}`,
      campaign: {
        title: job.employer,
        url: job.companyJobsLink
      },
      hostedParams: job.hostedParams || null,
      logoUrl: job.logoUrl,
      jobType: job.jobType,
      brandColor: job.brandColor
        ? job.brandColor.startsWith('#')
          ? job.brandColor
          : `#${job.brandColor}`
        : null,
      applyBtnLabel: job.applyBtnLabel,
      useDirectClickout: job.useDirectClickout
    };
  }
  getJobDetails(jobId: number, params: Params): Observable<IJobViewModel> {
    return this.httpClient
      .get<IJobViewModel>(`api/jobs/${jobId}`, { params })
      .pipe(this.mapJobQuestions());
  }

  submitAnswers(jobId: number, answers, params): Observable<any> {
    return this.httpClient
      .post(`api/jobs/${jobId}/apply`, answers, params)
      .pipe(
        map((response: any) => {
          return response.questions
            ? {
                ...response,
                questions: this.mapQuestions(response.questions)
              }
            : response;
        })
      );
  }

  private mapQuestions = questions => questions.map(this.mapQuestion);

  private mapQuestion = (question: Question) => {
    question.controlType = controlTypes[question.type];
    return question;
  };

  private mapJobQuestions = () =>
    pipe(
      map((job: IJobViewModel) => {
        job = {
          ...job,
          applyBtnLabel: job.applyBtnLabel
        };

        if (job.questions) {
          job.questions = job.questions
            .map(this.mapQuestion)
            .sort((a, b) => a.priority - b.priority);
        }

        return job;
      })
    );
}
