<div class="container-lg bg text pb-2 mt-4">
<h2 class="py-2">Terms of Use</h2>
<h3>PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THIS WEBSITE</h3>
<sub>(Revised and Effective – May 26, 2020)</sub>

<p>These Terms of Use govern use of the getgreatcareers.com website (the “Site”) and certain services and functionality we
may make available to you from time to time on the Site (collectively, the “Services”). These Terms of use do not apply
to other websites owned or operated by Appcast (“Other Appcast Sites”). These Terms of Use govern only your use of the
Site and the Services, and different terms of use may govern your use of Other Appcast Sites and the services offered
thereon.</p>

<p>By accessing and continuing to use the Site and Services after having an opportunity to review these Terms of Use, you
accept the Terms of Use in their entirety and without modification and agree that you are legally bound by them. These
Terms of Use constitute a binding agreement between (1) you (referred to herein as “you” and “your”) and (2) Appcast,
Inc. (referred to herein as “Appcast,” “we,” “us” and “our”). If you do not agree with these Terms of Use, please do not
use the Site or Services.</p>

<p>We may amend these Terms of Use at any time by posting an amended version on the then current Site. We may change these
Terms of Use at any time and from time to time. Please check these Terms of Use before using the Site to determine
whether a change has been made. By continuing to use the Site and Services following such amendment, you agree to the
Terms of Use then posted and such use will result in an addendum to this agreement.</p>

<h4>1. License to Use Services</h4>

<p>Subject to and conditional upon your compliance with these Terms of Use, Appcast provides you with access to the Site
and grants to you a limited, non-exclusive, non-transferable, right and license to use the Services.</p>

<h4>2. User Responsibilities; Prohibited Conduct</h4>

<p>You may browse and use the Site SOLELY FOR YOUR PERSONAL, NON-COMMERCIAL PURPOSES and in compliance with these
Terms of
Use and applicable law. You may not distribute, modify, transmit, publish, reuse, repost, or use the Site or any content
made available on the Site: (i) for public or commercial purposes; (ii) in a manner inconsistent with or in violation of
these Terms of Use; (iii) in violation of any applicable law, rule, regulation, or order; or (iv) in a manner that could
damage, disable, overburden or impair the Site or interfere with any other person’s use or enjoyment of the Site. For
avoidance of doubt, you may not use the Site to communicate with other users of the Site for any commercial purposes
outside of the Site.</p>

<p>Without limiting the foregoing, you will not (i) use any data mining robots, spiders or similar data gathering or
extraction methods; (ii) violate or attempt to violate the security of the Site or Services; (iii) reverse engineer or
decompile any part of the Site or Services; (iv) aggregate, copy or duplicate any of the Appcast IP (defined in Section
4 below) or information available on the Site or Services, other than as permitted by these Terms; (v) access data or
any part of the Site or Services not intended for you; (vi) use the Site for junk mail, ‘spamming’ solicitations
(commercial or non-commercial), chain letters, or bulk communications of any kind, including distribution lists to any
person who has not given specific permission to be included in such a list; (vii) invade the privacy of, obtain the
identity of, or request, harvest, or obtain or access personally identifying information, or other information about
others in connection with your use of the Site; (viii) obtain or attempt to access or otherwise obtain any materials or
information through any means not intentionally made available or provided for through the Site; (ix) upload, submit, or
otherwise communicate to us any private information of any third party without authorization, including, without
limitation, addresses, phone numbers, email addresses; (x) use or attempt to use another’s service or system without our
authorization, or create a false identity on the Site; (xi) impersonate any person or entity, or falsely state or
otherwise misrepresent yourself, including your age, email address, or any affiliation with any person or entity; or
(vii) transmit, distribute, introduce, or otherwise make available in any manner through the Site any computer virus,
keyloggers, spyware, worms, Trojan horses, timebombs, or other malicious or harmful programming (collectively,
“Viruses”). We do not have an obligation to detect the presence of such Viruses. If you download software or any other
content from the Site, you do so at your own risk.</p>

<p>You agree and acknowledge that you have the sole responsibility and liability for your use of the Site and for providing
or obtaining, and for maintaining, all of the hardware, software, electrical power, telecommunications, Internet
services, and other products or services necessary or desirable for you to access and use the Site.</p>

<h4>3. Access and Eligibility</h4>

<p>You need a supported Web browser to access the Site. The Site may cease to support a given Web browser, and your
continuous use of the Site will require you to download a supported Web browser.</p>

<p>You agree that (i) any and all information that you provide to us will be true, accurate, current, and complete
information about yourself, and (ii) you will continue to provide, maintain, and update any and all information in order
for it to remain accurate, current, and complete. You are solely responsible for all information that you provide to us.
</p>

<p>
You acknowledge and agree that (i) the Site’s purpose is to provide you with information about job opportunities of
third-party employers; (ii) Appcast has no control over or responsibility for (a) what additional conditions any
employer may impose on you for use of their website to submit an application, or (b) any part of the hiring decision
process by such employers; (iii) Appcast cannot confirm the accuracy, completeness, or availability of any job
advertisement, including the identity of such employer; and (iv) Appcast assumes no responsibility, and disclaims all
liability, for the content, accuracy, completeness, legality, reliability, or availability of any job advertisements.
</p>

<h4>4. Proprietary Rights</h4>

<p>
The Site and Services and all text, audio, video, pictures, music, images, graphics, web beacons, cookies, pixels,
information, data, content, and other technologies or materials displayed or used on, or downloadable from, the Site or
the Services (the “Appcast IP”) are the property of, or used with permission by, Appcast and are protected by copyright,
trademark and other laws and may not be used except as permitted in these Terms of Use or with the prior written
permission of the owner of such material. You may not modify the Appcast IP in any way or reproduce or publicly display,
perform, distribute or otherwise use any such Appcast IP for any public or commercial purpose. Any unauthorized use of
Appcast IP may violate copyright laws, trademark laws, laws of privacy and publicity, or other laws, rules and
regulations. You may not remove any copyright, trademark or other proprietary notices from the Site or any Appcast IP.
All rights to Appcast IP not expressly granted herein are reserved by and to the respective owners of such Appcast IP.
</p>
<p>
Certain trademarks, trade names, service marks and logos, icons and domain names used or displayed on the Site or via
the Services are registered and unregistered trademarks, trade names and service marks owned by Appcast and/or our
affiliates. Other trademarks, trade names and service marks used or displayed on the Site are the registered and
unregistered trademarks, trade names and service marks owned by their respective owners and used with permission by us.
Nothing grants or should be construed as granting, by implication, estoppel, or otherwise, any license or right to use
any trademarks, trade names, service marks or logos displayed on the Site or via the Services without our written
permission or the written permission of such third-party owner.
</p>
<h4>5. Privacy</h4>
<p>
Use of the Site and Services is governed by our Privacy Policy (linked at the bottom of each page of the Site) which is
incorporated into these Terms of Use by this reference.
</p>
<h4>6. Discontinuance, Modification & Restrictions.</h4>
<p>
Without limiting any other rights or remedies, in the event that we determine, in our sole discretion, that you have
breached any portion of these Terms of Use, or have otherwise demonstrated conduct that we deem to be inappropriate or
unacceptable in our sole discretion, we reserve the right to (i) warn you via e-mail that you have violated the Terms of
Use; (ii) temporarily or permanently discontinue your access to the Site or use of the Services; (iii) notify and/or
send content to and/or fully cooperate with law enforcement authorities for further action; and/or (iv) take any other
action we deem to be reasonably appropriate.
</p>
<p>
If your ability to access or use the Site is discontinued by us, then you may not attempt to regain access to the Site.
</p>
<p>
Additionally, we may suspend or discontinue the Site and/or any or all Services at any time for any reason without
notice.
</p>
<p>
In the event you discontinue using the Services or Site or we discontinue providing the Services or Site, the following
provisions will survive: Section 3 and Section 4 through and including Section 17, and any other provisions that by
their nature survive.
</p>
<h4>7. No Affiliation to Employers or Other Third-Party Websites; Linking</h4>
<p>
YOU ACKNOWLEDGE THAT WE ARE NOT IN ANY WAY AFFILIATED WITH, RELATED TO, OR SPONSORED BY ANY EMPLOYER. We are not
responsible for your access or use of (i) any website or software operated by any employer, whether or not accessed
through the Site, or (ii) any other website or software not owned, operated, or controlled by Appcast or its affiliates
(each, a “Third-Party Site”). Any such use or access is entirely at your own risk.
</p>
<p>
This Site may contain links to Third-Party Sites, including employer websites. We are not responsible for Third-Party
Sites or the parties that control them. All such links to Third-Party Sites are provided solely as a convenience to you.
If you use any of these links, you will leave our Site and no longer be governed by these Terms of Use or our Privacy
Policy. We are not liable for the content, quality, suitability, functionality or legality of any such sites. You hereby
waive any claim you might have against us with respect to such sites and their operators. Services available through
these links are not affiliated with us. All matters concerning such services are solely between you and the owners of
such Third-Party Sites.
</p>
<h4>8. Disclaimer of Warranties</h4>
<p>
USE OF THE SITE, SERVICES AND APPCAST IP IS AT YOUR SOLE RISK. APPCAST DOES NOT EVALUATE, SCREEN OR CENSOR JOB
DESCRIPTIONS SUBMITTED BY EMPLOYERS. APPCAST IS NOT INVOLVED IN AND DOES NOT CONTROL THE TRANSACTION BETWEEN AN EMPLOYER
AND YOU. ACCORDINGLY, APPCAST IS NOT RESPONSIBLE FOR (1) YOUR INTERACTION WITH ANY THIRD-PARTY WHO CONTACTS YOU,
INCLUDING EMPLOYERS, RELATING TO YOUR USE OF THE SERVICES OR THE SITE, OR (2) ANY HIRING DECISION BY ANY EMPLOYER
RELATING TO A JOB, OR ANY INACCURACY OR UNAVAILABILITY OF ANY JOB OPPORTUNITY.
</p>
<p>
THE SITE, SERVICES, AND APPCAST IP ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. APPCAST EXPRESSLY DISCLAIMS ALL
WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, WITH RESPECT TO THE SITE, SERVICES, AND APPCAST IP, INCLUDING WITHOUT
LIMITATION ANY WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE, OWNERSHIP OR NON-INFRINGEMENT.
</p>
<p>
APPCAST MAKES NO WARRANTY THAT THE SITE, SERVICES, OR APPCAST IP WILL MEET YOUR REQUIREMENTS, OR THAT THE SITE,
SERVICES, OR APPCAST IP OR YOUR USE THEREOF WILL BE UNINTERRUPTED, TIMELY, SECURE, CURRENT, ACCURATE, COMPLETE, OR ERROR
FREE. WE PROVIDE OUR SITE AND SERVICES FROM OUR OFFICES WITHIN THE UNITED STATES. WE MAKE NO REPRESENTATION THAT THE
SITE, SERVICES, OR APPCAST IP IS APPROPRIATE, LEGAL OR AVAILABLE FOR USE IN OTHER LOCATIONS.
</p>
<p>
APPCAST DOES NOT GUARANTEE OR WARRANT THAT THE SITE, OUR SERVERS, THE SERVICES, OR ANY APPCAST IP WILL BE FREE OF
INFECTION FROM VIRUSES, WORMS, OR OTHER CODE THAT HAS CONTAMINATING OR DESTRUCTIVE PROPERTIES. ANY CONTENT DOWNLOADED OR
OTHERWISE OBTAINED THROUGH THE USE OF THE SITE OR SERVICES IS AT YOUR OWN DISCRETION AND RISK.
</p>
<p>
APPCAST MAKES NO WARRANTIES AS TO ANY ACCURACY, COMPLETENESS, LEGALITY, RELIABILITY, OR AVAILABILITY OF ANY JOB
OPPORTUNITIES.
</p>
<p>
YOU ASSUME TOTAL RESPONSIBILITY FOR YOUR USE OF THE SITE, SERVICES, AND APPCAST IP, AND ANY LINKED SITES. YOUR SOLE
REMEDY AGAINST APPCAST FOR ANY GRIEVANCES RELATING TO THE SITE, SERVICES, OR APPCAST IP IS TO STOP USING THE SITE, THE
SERVICES, AND THE APPCAST IP.
</p>
<p>
THE WARRANTY DISCLAIMERS SET FORTH IN THIS SECTION 8 DO NOT APPLY TO THE EXTENT PROHIBITED BY LAW. PLEASE REFER TO YOUR
LOCAL LAWS FOR ANY SUCH PROHIBITIONS.
</p>
<h4>9. Limitations of Liability</h4>
<p>
IN NO EVENT WILL APPCAST BE LIABLE FOR ANY DAMAGES, DIRECT OR INDIRECT, INCLUDING CONSEQUENTIAL, SPECIAL, INCIDENTAL, OR
PUNITIVE DAMAGES (AND DAMAGES FROM LOST PROFITS, INTERRUPTION OF BUSINESS, LOST DATA), WHETHER FORESEEABLE OR NOT, WHICH
MAY ARISE OUT OF YOUR ACCESS TO, INABILITY TO ACCESS, OR YOUR USE OF THE SITE, THE SERVICES, OR APPCAST IP OR RESULTING
FROM YOUR USE OR ACTIONS RELATING TO ANY JOB POST OR ANY OTHER SERVICE PROVIDED BY OR OTHERWISE OBTAINED THROUGH THE
SITE.
</p>
<p>
IN NO EVENT WILL APPCAST BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM (I) YOUR USE OF THIS SITE, (II) YOUR USE OR
ACTIONS RELATING TO ANY JOB POST OR APPLICATION, (III) THE SERVICES PROVIDED BY APPCAST, (IV) YOUR USE OF ANY
THIRD-PARTY SITES, INCLUDING WEBSITES AND SOFTWARE OWNED AND OPERATED BY ANY EMPLOYER OR ANY THIRD-PARTY SITE, (V) ANY
CONTENT PROVIDED BY OR ACTIONS BY ANY THIRD PARTY, AS DESCRIBED IN SECTION 7 ABOVE, INCLUDING ANY HIRING DECISION BY ANY
EMPLOYER RELATING TO A JOB, OR ANY INACCURACY OR UNAVAILABILITY OF ANY JOB OPPORTUNITY.
</p>
<p>
THE LIMITATIONS OF LIABILITY SET FORTH IN THIS SECTION 9 DO NOT APPLY TO THE EXTENT PROHIBITED BY LAW. PLEASE REFER TO
YOUR LOCAL LAWS FOR ANY SUCH PROHIBITIONS.
</p>
<h4>10. Indemnification</h4>
<p class="uppercase">
to the extent permitted BY APPLICABLE LAW, YOU AGREE TO INDEMNIFY AND HOLD HARMLESS EACH OF APPCAST, ITS PARENTS,
SUBSIDIARIES, AFFILIATES, AND ALL OF THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS, from and against all
losses, expenses, damages, and costs, including reasonable attorneys’ fees, resulting from or related to (1) your use of
thE Site, ANY SERVICES, OR ANY APPCAST IP, or (2) the violation or attempted violation of these terms by you. IF YOU ARE
OBLIGATED TO INDEMNIFY US HEREUNDER, WE MAY, IN OUR SOLE DISCRETION, (1) CONTROL THE DEFENSE AND DISPOSITION OF ANY SUCH
CLAIMS AT YOUR SOLE COST AND EXPENSE OR (2) REQUIRE YOU TO DEFEND APPCAST WITH COUNSEL OF OUR CHOOSING. WITHOUT LIMITING
THE FOREGOING, YOU MAY NOT SETTLE, COMPROMISE OR IN ANY OTHER MANNER DISPOSE OF ANY THIRD-PARTY CLAIM WITHOUT OUR
express written CONSENT.
</p>
<h4>11. Governing Law; Jurisdiction.</h4>
<p>
These Terms of Use and your use of the Site and Services will be governed by and construed in accordance with the laws
of the State of New Hampshire, without giving effect to its conflicts of law provisions. By accessing or using the Site
or Services, you consent and submit to the exclusive jurisdiction and venue of the state and federal courts located in
New Hampshire. A printed version of these Terms of Use will be admissible in judicial and administrative proceedings
based upon or relating to these Terms of Use to the same extent and subject to the same conditions as other business
documents and records originally generated and maintained in printed form.
</p>
<p>
ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE SITE MUST BE COMMENCED
WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY WAIVED.
</p>
<p>
<strong>YOU HEREBY WAIVE THE RIGHT TO TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION. YOU MAY BRING CLAIMS AGAINST APPCAST
ONLY IN YOUR INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION OR SIMILAR
CONSOLIDATED ACTION.</strong>
</p>
<p>
THIS SECTION 11 DOES NOT APPLY TO THE EXTENT PROHIBITED BY LAW. PLEASE REFER TO YOUR LOCAL LAWS FOR ANY SUCH
PROHIBITIONS.
</p>
<h4>12. Disputes.</h4>
<p>
You are solely responsible for your interactions with any third party, including any interaction with a chatbot or with
popups.
</p>
<p>
You may not take legal action against Appcast without first (a) sending us, a detailed written description of the facts
and law out of which your claim arises; and (b) negotiating with us, in good faith, toward resolution of the dispute.
</p>
<h4>13. Force Majeure.</h4>
<p>
Appcast will not be liable for failure or delay in performing its obligations because of causes beyond its reasonable
control, including without limitation acts of God, terrorism, war, riots, fire, earthquake, flood or degradation or
failure of third-party networks or communications infrastructure.
</p>
<h4>14. Relationship</h4>
<p>
Without limiting any other provision of these Terms of Use, these Terms of Use create no agency, partnership, joint
venture, or employee-employer relationship between you and Appcast.
</p>
<h4>15. Miscellaneous</h4>
<p>
In the event that any provision of these Terms of Use conflicts with applicable law, rule, regulation or order or if any
provision is held invalid by a court with competent jurisdiction, then such provision will be deemed to be restated to
reflect as nearly as possible the original intentions of the parties, and the remainder of these Terms of Use will
remain in full force and effect. Any failure by Appcast to insist upon or enforce strict performance of any provision of
these Terms of Use or to exercise any right under these Terms of Use will not be construed as a waiver or relinquishment
of our right to assert or rely upon any such provision or right in that or any other instance, rather, the same will be
and remain in full force and effect.
</p>
<p>
You represent to us that: (i) you have the authority to access and use the Site and Services according to these Terms of
Use; and (ii) you have authority to enter into a binding contract on behalf of the company or organization that employs
you. These Terms of Use constitute the entire understanding between the parties as to the subject matter hereof and
supersede all prior agreements and understandings. We may communicate with you by posting notice on this Site. You agree
that any notices, disclosures, amendments or other communications provided to you electronically satisfy any
requirement, legal or otherwise, that such communication be in writing.
</p>
<h4>16. Contact</h4>
<p>
We welcome your comments and questions. Please contact us at: contact&#64;getgreatcareers.com
</p>
<p>
Copyright © 2021 Appcast, Inc. All rights reserved
</p>
<p>
LAST UPDATED: May 2020
</p>
</div>

