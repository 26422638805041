<label
  class="checkbox-label"
  [class.checkbox-disabled]="disabled"
>
  <span
    class="checkbox-wrapper"
    [class.checkbox-checked]="isChecked"
  >
    <input
      type="checkbox"
      [ngModel]="isChecked"
      [disabled]="disabled"
      (ngModelChange)="checkChange($event)"
    />
    <span class="checkbox-inner"></span>
  </span>
  <span>
    <ng-content></ng-content>
  </span>
</label>
