<button
  [ngClass]="className"
  [disabled]="disabled"
  (click)="!loading && libClick.emit($event)"
  [class.loading]="loading"
  [style.border-color]="btnColor"
  [style.color]="btnColor"
  [attr.form]="form"
>
  <span>
    <ng-content></ng-content>
  </span>
  <div
    *ngIf="loading"
    class="loader"
  ></div>
</button>
