<input
  *ngIf="!addon"
  class="input"
  [type]="type"
  [placeholder]="placeholder"
  [disabled]="disabled"
  [(ngModel)]="displayValue"
  [matAutocomplete]="autocomplete"
  [matAutocompleteDisabled]="!autocomplete"
  (blur)="touch()"
  (focus)="gcFocus.emit()"
  (ngModelChange)="modelChanged($event)"
>

<div
  *ngIf="addon"
  class="input input-group"
  [class.disabled]="disabled"
  matAutocompleteOrigin 
  #origin="matAutocompleteOrigin"
  (click)="input.focus()"
>
  <input
    #input
    [type]="type"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [(ngModel)]="displayValue"
    [matAutocomplete]="autocomplete"
    [matAutocompleteConnectedTo]="origin"
    [matAutocompleteDisabled]="!autocomplete"
    (blur)="touch()"
    (focus)="gcFocus.emit()"
    (ngModelChange)="modelChanged($event)"
  >
  <span class="addon">
    <ng-template [ngTemplateOutlet]="addon">
    </ng-template>
  </span>
</div>
