import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlErrorsDirective } from './control-errors.directive';
import { FORM_ERRORS, defaultErrors } from './form-errors';
import { FormSubmitDirective } from './form-submit/form-submit.directive';
import { ControlErrorsComponent } from './control-errors.component';
import { ControlErrorContainerDirective } from './control-errors-container.directive';



@NgModule({
  declarations: [ ControlErrorsDirective, FormSubmitDirective, ControlErrorsComponent, ControlErrorContainerDirective],
  imports: [
    CommonModule
  ],
  exports: [ControlErrorsDirective, FormSubmitDirective, ControlErrorContainerDirective]
})
export class ControlErrorsModule {
  static forRoot(errors = {}): ModuleWithProviders<ControlErrorsModule> {
    return {
      ngModule: ControlErrorsModule,
      providers: [{
        provide: FORM_ERRORS, useValue: {...defaultErrors, ...errors}
      }]
    };
  }
}
