import { ReplaySubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class RadioService {
  selected$ = new ReplaySubject<any>(1);
  touched$ = new Subject<void>();
  disabled$ = new ReplaySubject<boolean>(1);
  name$ = new ReplaySubject<string>(1);
  touch(): void {
    this.touched$.next();
  }
  select(value: any): void {
    this.selected$.next(value);
  }
  setDisabled(value: boolean): void {
    this.disabled$.next(value);
  }
  setName(value: string): void {
    this.name$.next(value);
  }
}
