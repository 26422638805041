import { NgModule } from '@angular/core';
import { QuestionsComponent } from './questions.component';
import { CommonModule } from '@angular/common';
import { UploadModule } from '../upload/upload.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { DropdownModule } from '../dropdown/dropdown.module';
import { RadioButtonModule } from '../radio-button/radio-button.module';
import { InputModule } from '../input/input.module';
import { ControlErrorsModule } from '../control-errors/control-errors.module';
import { ButtonModule } from '../button/button.module';
import { DatePickerModule } from '../datepicker/datepicker.module';
// eslint-disable-next-line
const errors = { 'required-radio'() { return 'A selection must be made to continue to the application'; } };

@NgModule({
  declarations: [QuestionsComponent],
    imports: [
        ButtonModule,
        UploadModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RadioButtonModule,
        InputModule,
        DropdownModule,
        CheckboxModule,
        ControlErrorsModule.forRoot(errors),
        DatePickerModule
    ],
  exports: [QuestionsComponent]
})
export class QuestionsModule { }
