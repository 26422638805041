import { ComponentRef, Directive, ViewContainerRef } from '@angular/core';
import { ControlErrorsComponent } from './control-errors.component';

@Directive({
  selector: '[controlErrorContainer]'
})
export class ControlErrorContainerDirective {
  ref: ComponentRef<ControlErrorsComponent>;
  constructor(public vcr: ViewContainerRef) {}
}
