<div class="container-lg text">
  <p>Get Great Careers is on a mission to match every single job seeker with their dream career! We are a job search web
    service that offers endless job listings in almost all industries.</p>

  <p>If our team can be of any resource to you, please don’t hesitate to reach out. <a
      class="mr-sm-2"
      href="mailto:contact@getgreatcareers.com"
    >contact&#64;getgreatcareers.com</a></p>

  <p>See where your job search (and career!) takes you with Get Great Careers!</p>
</div>
