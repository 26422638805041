import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef, inject, DestroyRef
} from '@angular/core';
import { UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import {
  distinctUntilChanged,
  debounceTime,
  takeUntil,
  map,
  filter
} from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { LocationService } from 'src/core/services/location.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'gc-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchBoxComponent implements OnInit, OnChanges, OnDestroy {
  private destroyRef = inject(DestroyRef);

  location: UntypedFormControl;
  keyword: UntypedFormControl;
  selectedAutocomplete: string;

  @Input() loading: boolean;
  @Input() showLocationTooltip: boolean;
  @Input() initialKeyword: string;
  @Input() initialLocation: string;
  @Input() alwaysVisible = false;
  @Input() locationLoading = false;

  @Output() showLocationTooltipChange = new EventEmitter();
  @Output() searchClick = new EventEmitter();
  @Output() locationChanged = new EventEmitter();

  message: string;
  searchVisible: boolean;

  cancelLocationSearch$ = new Subject<void>();

  locations: { address: string }[];

  constructor(
    private fb: UntypedFormBuilder,
    private locationService: LocationService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.location = this.fb.control({
      value: this.initialLocation || '',
      disabled: this.loading
    });
    this.keyword = this.fb.control({
      value: this.initialKeyword || '',
      disabled: this.loading
    });

    this.keyword.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.searchParamsChanged());

    this.location.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        map(location => this.locationChanged.emit(location)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => this.locationChange());

    this.searchParamsChanged();
    this.searchVisible =
      this.alwaysVisible || (!this.initialLocation && !this.initialKeyword);
  }

  ngOnDestroy() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.loading && this.location && this.keyword) {
      if (changes.loading.currentValue) {
        this.location.disable();
        this.keyword.disable();
      } else {
        this.location.enable();
        this.keyword.enable();
      }
    }

    if (changes.showLocationTooltip && this.showLocationTooltip) {
      setTimeout(() => {
        this.showLocationTooltipChange.emit(false);
      }, 10000);
    }

    if (
      changes.initialLocation &&
      changes.initialLocation.currentValue &&
      !changes.initialLocation.previousValue &&
      this.location && !this.location.value
    ) {
      this.location.setValue(this.initialLocation);
    }
  }

  search() {
    this.searchClick.emit({
      keyword: this.keyword.value || '',
      location: this.location.value || ''
    });
    this.searchVisible = this.alwaysVisible || !this.message;
  }

  showSearchForm() {
    this.searchVisible = true;
  }

  locationChange() {
    this.searchParamsChanged();
    if (this.selectedAutocomplete !== this.location.value) {
      this.cancelLocationSearch$.next();
      this.locationService
        .searchLocations(this.location.value)
        .pipe(takeUntil(this.cancelLocationSearch$))
        .subscribe(locations => {
          this.locations = locations;
          this.cdr.detectChanges();
        });
    }
  }

  optionSelectedHandler(event) {
    this.selectedAutocomplete = event.option.value;
  }

  private searchParamsChanged() {
    const keyword = this.keyword.value;
    const location = this.location.value;

    this.message =
      keyword && location
        ? `${keyword} in ${location}`
        : keyword
        ? keyword
        : location;
  }
}
