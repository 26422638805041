import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy
} from '@angular/core';
import { IJobViewModel } from 'src/core/view-models';

@Component({
  selector: 'gc-jobs-tile',
  templateUrl: './jobs-tile.component.html',
  styleUrls: ['./jobs-tile.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobsTileComponent implements OnInit {
  @Input() job: IJobViewModel;

  constructor() {}

  ngOnInit(): void {}
}
