
  <ng-content></ng-content>
  <div
    class="fade"
    *ngIf="showFade"
  >
    <a (click)="showFull()">
      <span class="more">show more</span>
      <span class="less">show less</span>
    </a>
  </div>


