import { JobsState } from './state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { IJob } from 'src/core/models';
import { Injectable } from '@angular/core';
import { LoadJobs, LoadSeletedJob, SeletJob, SubmitAnswers } from './actions';
import {
  IJobViewModel,
  EmployerPipelineViewModel,
  ClickoutJobViewModel,
  HostedJobViewModel
} from '../../view-models';
import { Params } from '@angular/router';

@Injectable()
export class JobsStateFacade {
  constructor(private store: Store) {}

  @Select(JobsState.jobs) jobs$: Observable<IJobViewModel[]>;
  @Select(JobsState.pages) pages$: Observable<{
    total: number;
    currentPage: number;
    perPage: number;
  }>;
  @Select(JobsState.pipeline) employerPipeline$: Observable<EmployerPipelineViewModel>;
  @Select(JobsState.clickoutJob) clickoutJob$: Observable<ClickoutJobViewModel>;
  @Select(JobsState.selectedJob) selectedJob$: Observable<HostedJobViewModel | IJobViewModel>;
  @Select(JobsState.pixelScriptSource) pixelSource$: Observable<string>;
  @Select(JobsState.isNotQualifiedApply) isNotQualifiedApply$: Observable<boolean>;
  @Select(JobsState.redirectUrl) redirectUrl$: Observable<string>;

  loadJobs(searchTerm: string, location: string, page: number, params: any) {
    return this.store.dispatch(
      new LoadJobs(searchTerm, location, page, params)
    );
  }

  loadSelectedJob(id: number, jobReference: string, params: Params) {
    return this.store.dispatch(new LoadSeletedJob(id, jobReference, params));
  }

  selectJob(id: string) {
    return this.store.dispatch(new SeletJob(id));
  }

  submitAnswers(id: number, answers, settings): Observable<any> {
    return this.store.dispatch(new SubmitAnswers(id, answers, settings));
  }
}
