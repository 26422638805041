import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  text: string;
}

@Component({
  selector: 'gc-continue-dialog',
  templateUrl: './continue-dialog.component.html',
  styleUrls: ['./continue-dialog.component.less']
})
export class ContinueDialogComponent implements OnInit {
  @Output() continue = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<ContinueDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.dialogRef.addPanelClass('dialog-panel');
  }

  ngOnInit(): void {}

  continueClick(event): void {
    this.continue.emit(event);
  }
}
