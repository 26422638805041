import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { PopupType } from '@shared/enums';
import { MatDialog } from '@angular/material/dialog';
import { ContinueDialogComponent } from '@shared/components';
import { Injectable } from '@angular/core';
import {
  EXPIRED_JOB_DESCRIPTION,
  KNOCKOUT_NOT_FIT_QUESTIONS_DESCRIPTION
} from '@shared/consts';

@Injectable()
export class NotificationGuard  {
  showPopupKey = 'rfh';
  dialogs = {
    // tslint:disable-next-line: max-line-length
    [PopupType.NOT_FIT_CANDIDATE_POPUP]: `${KNOCKOUT_NOT_FIT_QUESTIONS_DESCRIPTION}...but you are in luck! View and
  apply to these personalized jobs!`,
    [PopupType.EXPIRED_JOB_POPUP]: EXPIRED_JOB_DESCRIPTION
  };

  constructor(public dialog: MatDialog, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const key = Number(route.queryParams[this.showPopupKey]);

    const dialog = this.dialogs[key];

    if (dialog) {
      const existingRef = this.dialog.getDialogById(`dialog_${key}`);

      if (existingRef) {
        return true;
      }

      const ref = this.dialog.open(ContinueDialogComponent, {
        maxWidth: 500,
        disableClose: true,
        data: { text: dialog },
        closeOnNavigation: true,
        id: `dialog_${key}`,
        panelClass: 'continue-panel'
      });

      ref.beforeClosed().subscribe(() =>
        this.router.navigate(['/jobs'], {
          queryParamsHandling: 'merge',
          queryParams: { [this.showPopupKey]: null }
        })
      );
    }

    return true;
  }
}
