import { Component, OnInit, forwardRef, Input, ChangeDetectorRef, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'lib-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements OnInit, ControlValueAccessor {
  @Input() isChecked: boolean;
  @Input() disabled: boolean;

  @Output() isCheckedChange = new EventEmitter();

  onChange = (_) => { };
  onTouched = () => { };

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  writeValue(value: boolean): void {
    this.isChecked = value;
    this.cdr.markForCheck();
  }

  registerOnChange(fn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
    this.cdr.markForCheck();
  }


  checkChange(checked: boolean) {
    if (!this.disabled) {
      this.isChecked = checked;
      this.onChange(this.isChecked);

      this.isCheckedChange.emit(this.isChecked);
    }
  }

}
