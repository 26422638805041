import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as camelcaseKeys from 'camelcase-keys';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  // add error messaging
  constructor() {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((response: any) => {
        if (response.body) {
          response.body = camelcaseKeys(response.body, { deep: true });
        }

        return response;
      })
    );
  }
}
