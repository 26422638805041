import { Directive, Input, HostListener, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, fromEvent, NEVER, ReplaySubject } from 'rxjs';
import { shareReplay, tap, takeUntil } from 'rxjs/operators';

@Directive({
  // eslint-disable-next-line
  selector: 'form'
})
export class FormSubmitDirective {
  @Input() set skip(skip: boolean) {
    if (skip) {
      this.stop$.next(undefined);
      // this.stop$.complete();
    }
  }

  stop$ = new ReplaySubject();

  submit$ = this.skip ? NEVER : fromEvent(this.element, 'submit')
    .pipe(tap(() => {
      if (this.element.classList.contains('submitted') === false) {
        this.element.classList.add('submitted');
      }
    }), shareReplay(1), takeUntil(this.stop$));

  constructor(private host: ElementRef<HTMLFormElement>) {
  }

  get element() {
    return this.host.nativeElement;
  }

}
