import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { NotificationGuard } from './notification.guard';

@Injectable()
export class QueryParamsGuard  {
  constructor(
    private router: Router,
    private notification: NotificationGuard
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const hasQueryPrams = ['jg', 'e', 'keyword', 'coju', 'location', 'b'].some(
      key => route.queryParams[key] != null && route.queryParams[key] !== ''
    );

    if (hasQueryPrams) {
      return this.router.createUrlTree(['/jobs'], {
        queryParams: route.queryParams,
        queryParamsHandling: 'merge'
      });
    }

    this.notification.canActivate(route);
    return true;
  }
}
