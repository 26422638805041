import { Component, OnInit, ChangeDetectorRef, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'lib-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder = ' ';
  @Input() disabled = false;
  @Input() type = 'text';

  displayValue: string | number;
  private value: string | number;

  change = (_: any) => { };
  touch = () => { };

  constructor(private cdr: ChangeDetectorRef) { }

  writeValue(obj: any) {
    this.setValue(obj, false);
    this.cdr.detectChanges();
  }

  registerOnChange(fn: any) {
    this.change = fn;
  }

  registerOnTouched(fn: any) {
    this.touch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    this.cdr.detectChanges();
  }

  ngOnInit() { }

  modelChanged(value): void {
    this.setValue(value);
  }

  private setValue(value, emit = true): void {
    this.value = value;

    this.displayValue = value != null ? value : '';

    if (emit) {
      this.change(this.value);
      this.touch();
    }
  }

}
