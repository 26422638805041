import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocationService } from '@core/services';
import { Observable, Subject } from 'rxjs';
import { PopularSearchesProvider } from '@core/data-providers';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomePageComponent implements OnInit {
  userLocation: string;
  locationLoaded = false;
  popularSearches$: Observable<string[]>;
  showLocationTooltip  = false;

  constructor(
    private router: Router,
    private locationService: LocationService,
    private popularSearchesProvider: PopularSearchesProvider
  ) {}

  ngOnInit(): void {
    this.locationService.getUserLocation().subscribe({
      next: location => {
        this.userLocation = location;
        if (location) {
          this.showLocationTooltip = true;
        }
      },
      complete: () => (this.locationLoaded = true)
    });

    this.popularSearches$ = this.popularSearchesProvider.getPopularSearches();
  }

  search({ keyword, location }) {
    let queryParams: any = {
      keyword
    };

    if (location) {
      queryParams.location = location;
    } else {
      queryParams.skipLocationDetection = true;
    }
    this.router.navigate(['jobs'], {
      queryParams,
      queryParamsHandling: 'merge'
    });
  }

  popularSearchClick(term: string): void {
    this.search({ keyword: term, location: this.userLocation });
  }
}
