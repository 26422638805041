import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { JobsPageComponent } from './pages/jobs/jobs.component';
import { HomePageComponent } from './pages/home/home.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { AboutComponent } from './pages/about/about.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import {
  QueryParamsGuard,
  NotificationGuard,
  BoostGuard,
  UnsubscribeGuard
} from '@core/services';
import { BoostComponent } from './pages/boost/boost.component';
import { UnsubscribePageComponent } from './pages/unsubscribe-page/unsubscribe-page.component';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: '',
    canActivate: [QueryParamsGuard],
    component: HomePageComponent
  },
  {
    path: 'jobs',
    canActivate: [NotificationGuard],
    component: JobsPageComponent,
    pathMatch: 'prefix'
  },
  {
    path: 'jobs/:title/:jobId',
    canActivate: [ NotificationGuard ],
    component: JobsPageComponent,
    pathMatch: 'prefix'
  },
  {
    path: 'terms-of-use',
    component: TermsComponent
  },
  {
    path: 'privacy',
    component: PrivacyComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'boost-clickout',
    canActivate: [BoostGuard],
    component: BoostComponent
  },
  {
    path: 'unsubscribe',
    canActivate: [UnsubscribeGuard],
    component: UnsubscribePageComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
