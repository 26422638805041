import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from '../card/card.module';
import { QuickApplyComponent } from './quick-apply.component';
import { ButtonModule } from '../button/button.module';



@NgModule({
  declarations: [QuickApplyComponent],
  imports: [
    CommonModule,
    CardModule,
    ButtonModule
  ],
  exports: [QuickApplyComponent]
})
export class QuickApplyModule { }
