<div class="container-fluid bg flex-2">
  <div class="d-flex flex-column align-items-center">
    <h1 class="d-none d-md-flex">Employment for Everyone</h1>
    <h2 class="d-flex d-md-none mb-3">Employment for Everyone</h2>

    <gc-search-box
      (searchClick)="search($event)"
      [(showLocationTooltip)]="showLocationTooltip"
      (locationChanged)="userLocation = $event"
      [locationLoading]="!locationLoaded"
      [initialLocation]="userLocation"
      [alwaysVisible]="true"
    ></gc-search-box>
  </div>
</div>

<div class="container-fluid d-flex flex-column align-items-center justify-content-center flex-1">
  <div class="popular-searches-container">
    <gc-popular-searches
      [popularSearches]="popularSearches$ | async"
      (termClicked)="popularSearchClick($event)"
    ></gc-popular-searches>
  </div>
</div>

