import { InjectionToken } from '@angular/core';

export const defaultErrors = {
  required: error => 'This field is required.',
  minLength: ({ requiredLength, type = 'characters' }) =>
    `Value must be longer than ${requiredLength} ${type}.`,
  maxLength: ({ requiredLength, type = 'characters' }) =>
    `Value must be less than ${requiredLength} ${type}.`,
  equal: ({ requiredLength, type = 'characters' }) =>
    `Value must equal ${requiredLength} ${type}.`,
  email: error => 'You have entered an invalid e-mail address.',
  readFileError: error => 'An error occurred in the upload. Please try again.',
  phone: error => 'Please use 0-9 and +-() symbols only.',
  date: error => 'Please use valid date mm-dd-yyyy.',
  fileTypes: error => `Incorrect file format, please upload "${error}"`,
  numberError: error => 'Please use 0-9 symbols only.',
  checkboxError: error => 'Please select at least one option.'
};

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  factory: () => defaultErrors
});
