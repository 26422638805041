import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { JobsPageComponent } from './pages/jobs/jobs.component';
import { HomePageComponent } from './pages/home/home.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { FooterComponent } from './components/footer/footer.component';
import { AcceptCookieComponent } from './components/accept-cookie/accept-cookie.component';
import { AboutComponent } from './pages/about/about.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiInterceptor } from '@core/interceptors/api.interceptor';
import { EmployerPipeleneComponent } from './components/employer-pipelene/employer-pipelene.component';
import { BoostComponent } from './pages/boost/boost.component';
import { environment } from '../environments/environment';
import { EmailSubscriberModule } from './components/email-subscriber/email-subscriber.module';
import { UnsubscribePageComponent } from './pages/unsubscribe-page/unsubscribe-page.component';
import { ControlErrorsModule } from 'src/common/lib/control-errors/control-errors.module';
import { GOOGLE_DRIVE_CREDENTIALS } from 'src/common/lib/upload/google-drive.token';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha-2';

@NgModule({
  declarations: [
    AppComponent,
    JobsPageComponent,
    TermsComponent,
    PrivacyComponent,
    HomePageComponent,
    FooterComponent,
    AcceptCookieComponent,
    AboutComponent,
    NotFoundComponent,
    EmployerPipeleneComponent,
    BoostComponent,
    UnsubscribePageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    CoreModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NoopAnimationsModule,
    EmailSubscriberModule,
    NoopAnimationsModule,
    ControlErrorsModule.forRoot({
      'required-radio': () =>
        'A selection must be made to continue to the application'
    }),
    RecaptchaV3Module
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6LcDmNoZAAAAAIx3uf5jtzjM-6SdkJOo4Exd0gPu'
    },
    {
      provide: GOOGLE_DRIVE_CREDENTIALS,
      useValue: {
        apiKey: 'AIzaSyC3H3sDLm4qgD2O8t3VCVtYqS9CLpv27Sk',
        clientId:
          '442457156099-fdthk74viutbuqnp5s55lhu1ljheb5gr.apps.googleusercontent.com'
      }
    },
    { provide: 'googleTagManagerId', useValue: environment.gtm },
    { provide: 'googleTagManagerAuth', useValue: environment.gtmAuth },
    { provide: 'googleTagManagerPreview', useValue: environment.gtmPreview }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
