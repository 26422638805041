import {
  Component,
  AfterViewInit,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'gc-native-ad',
  templateUrl: './native-ad.component.html',
  styleUrls: ['./native-ad.component.less']
})
export class NativeAdComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() keyword = '';
  @Input() location = '';

  iframeURL: SafeUrl;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.iframeURL = this.getIframeURL();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.location?.currentValue) {
      this.iframeURL = this.getIframeURL();
    }
  }

  getIframeURL(): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      `https://jobadvert.appcast.io/v90oqokme0/iframe?keyword=${this.keyword}&location=${this.location}`
    );
  }

  ngAfterViewInit(): void {
    (window['adsbygoogle'] = window['adsbygoogle'] || []).push({});
  }
}
