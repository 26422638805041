import { Component } from '@angular/core';

@Component({
  selector: 'lib-quick-apply',
  templateUrl: './quick-apply.component.html',
  styleUrls: ['./quick-apply.component.less']
})
export class QuickApplyComponent {
  constructor() {}
}
