import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnDestroy,
  OnChanges,
  SimpleChanges, inject, DestroyRef
} from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { emailRegex } from '../../consts';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'gc-email-subscribe-form',
  templateUrl: './email-subscribe-form.component.html',
  styleUrls: ['./email-subscribe-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailSubscribeFormComponent implements OnInit, OnDestroy, OnChanges {
  private destroyRef = inject(DestroyRef);

  @Input() location: string;
  @Input() keyword: string;
  @Input() loading: boolean;

  @Output() setAlertClick = new EventEmitter();

  emailControl: UntypedFormControl;
  keywordControl: UntypedFormControl;
  locationControl: UntypedFormControl;
  showFields = false;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loading) {
      this.loading ? this.disableControls() : this.enableControls();
    }
  }

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.emailControl = this.fb.control('');
    this.keywordControl = this.fb.control(this.keyword || '');
    this.locationControl = this.fb.control(this.location || '');

    this.keywordControl.valueChanges
      .pipe(debounceTime(200), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.locationControl.updateValueAndValidity();
      });
  }

  formSubmit() {
    const validControls =
      this.locationControl.value || this.keywordControl.value;

    if (!this.emailControl.value) {
      this.emailControl.setErrors({
        required: 'Please enter an email address'
      });
      return;
    }

    if (emailRegex.test(this.emailControl.value)) {
      if (validControls) {
        this.setAlertClick.emit({
          email: this.emailControl.value,
          keyword: this.keywordControl.value,
          location: this.locationControl.value
        });
      } else {
        this.locationControl.setErrors({
          required: `Both location and keyword can't be empty`
        });
      }
    } else {
      this.emailControl.setErrors({ pattern: 'Invalid email' });
    }
  }

  private disableControls() {
    this.emailControl.disable();
    this.locationControl.disable();
    this.keywordControl.disable();
  }

  private enableControls() {
    this.emailControl && this.emailControl.enable();
    this.locationControl && this.locationControl.enable();
    this.keywordControl && this.keywordControl.enable();
  }
}
