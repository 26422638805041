import {
  Question,
  QuestionTypeEnum,
  QuestionControlTypeEnum
} from '../models/job.model';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { Injectable } from '@angular/core';
import { snakeCase } from 'snake-case';
import {
  checkboxValidator,
  datepickerValidator,
  emailDomainValidator,
  emailValidator,
  lengthValidator,
  numberValidator,
  phoneValidator
} from './questions-validators.helper';

@Injectable({
  providedIn: 'root'
})
export class QuestionControlService {
  public toFormGroup(questions: Question[]): UntypedFormGroup {
    const group: any = {};

    questions?.forEach(question => {
      if (question.type === QuestionTypeEnum.check_boxes) {
        const controls = question.options.map(() => new UntypedFormControl(false));
        group[question.id] = new UntypedFormArray(
          controls,
          question.required && !question.condition ? checkboxValidator() : null
        );
        return;
      }
      if (question.type === QuestionTypeEnum.email) {
        if (question.required) {
          group[question.id] = new UntypedFormControl(
            question.value || null,
            !question.condition
              ? [Validators.required, emailValidator(), emailDomainValidator()]
              : [emailValidator(), emailDomainValidator()]
          );
          return;
        }

        group[question.id] = new UntypedFormControl(
          question.value || null,
          !question.condition
            ? [emailValidator(), emailDomainValidator()]
            : null
        );
        return;
      }

      if (
        question.type === QuestionTypeEnum.number ||
        question.type === QuestionTypeEnum.zip
      ) {
        const validators = [];

        if (!question.condition) {
          validators.push(numberValidator());

          if (question.required) {
            validators.push(Validators.required);
          }
        }

        group[question.id] = new UntypedFormControl(
          question.value || null,
          validators.length ? validators : null
        );
        return;
      }

      if (question.type === QuestionTypeEnum.phone) {
        const validators = [];

        if (!question.condition) {
          validators.push(phoneValidator());

          if (question.required) {
            validators.push(Validators.required);
          }

          if (question.minLength || question.maxLength) {
            validators.push(
              lengthValidator(question.minLength, question.maxLength, 'digits')
            );
          }
        }

        group[question.id] = new UntypedFormControl(
          question.value || null,
          validators.length ? validators : null
        );
        return;
      }

      if (question.type === QuestionTypeEnum.date) {
        if (question.required) {
          group[question.id] = new UntypedFormControl(
            question.value || null,
            !question.condition
              ? [Validators.required, datepickerValidator()]
              : null
          );
          return;
        }

        group[question.id] = new UntypedFormControl(
          question.value || null,
          !question.condition ? datepickerValidator() : null
        );
        return;
      }

      group[question.id] = new UntypedFormControl(
        question.value || null,
        question.required && !question.condition ? Validators.required : null
      );
    });
    return new UntypedFormGroup(group);
  }

  public toFormData(
    formGroup: UntypedFormGroup,
    questions: Question[],
    params,
    additionalFields = {}
  ) {
    const answers = this.getAnswers(formGroup.value, questions);

    const apply = {
      ...Object.entries(params).reduce((acc, [k, v]) => {
        acc[snakeCase(k)] = v;
        return acc;
      }, {}),
      apply_questions_attributes: answers
    };

    return this.createFormData({ apply, ...additionalFields });
  }

  public getAnswers(form: UntypedFormGroup, questions) {
    return questions.map(({ options, id, controlType }) => {
      if (controlType === QuestionControlTypeEnum.upload) {
        return { id, ...form[id], question: form[id] && form[id].file };
      }

      const question =
        controlType === QuestionControlTypeEnum.checkbox
          ? options.filter((opt, i) => form[id] && form[id][i])
          : form[id];
      return { id, question };
    });
  }

  private createFormData(
    object,
    form?: FormData,
    namespace?: string
  ): FormData {
    const formData = form || new FormData();
    for (const property in object) {
      if (!object.hasOwnProperty(property) || !object[property]) {
        continue;
      }
      const formKey = namespace ? `${namespace}[${property}]` : property;
      if (object[property] instanceof Date) {
        formData.append(formKey, object[property].toISOString());
      } else if (
        typeof object[property] === 'object' &&
        !(object[property] instanceof File)
      ) {
        this.createFormData(object[property], formData, formKey);
      } else {
        formData.append(formKey, object[property]);
      }
    }
    return formData;
  }
}
