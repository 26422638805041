import { Component, OnInit, Input, Optional, Inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EMAIL_COOKIE_KEY } from '@shared/consts';

@Component({
  template: `<gc-email-subscriber
    [keyword]="data?.keyword"
    [location]="data?.location"
    [activationType]="data?.activationType"
    [formClass]="data?.formClass"
  >
  </gc-email-subscriber>`
})
export class EmailSubscriberDialogComponent implements OnInit {
  constructor(
    private cookieService: CookieService,
    private modalRef: MatDialogRef<EmailSubscriberDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    const emailAlertCookie = Boolean(this.cookieService.get(EMAIL_COOKIE_KEY));

    if (emailAlertCookie && this.modalRef) {
      Promise.resolve().then(() => this.modalRef.close());
    }
  }
}
