import { Component, OnInit, ViewChild, TemplateRef, OnDestroy, inject, DestroyRef } from '@angular/core';
import { SubscriptionProvider } from '../../../core/data-providers';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { EMAIL_COOKIE_KEY } from '../../../shared/consts';
import { EmailSubscriberComponent } from '../../components/email-subscriber/email-subscriber.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  templateUrl: './unsubscribe-page.component.html',
  styleUrls: ['./unsubscribe-page.component.less']
})
export class UnsubscribePageComponent implements OnInit, OnDestroy {
  private destroyRef = inject(DestroyRef);

  message: string;
  @ViewChild('dialog') dialogTemplate: TemplateRef<any>;
  unsubscribed: boolean;
  loading: boolean;

  constructor(
    private subscriptionProvider: SubscriptionProvider,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private cookieService: CookieService
  ) {}

  ngOnDestroy() {}

  ngOnInit(): void {
    const token = this.route.snapshot.queryParams.token;
    this.loading = true;
    this.subscriptionProvider.unsubscribeFromEmails(token).subscribe({
      next: response => {
        this.unsubscribed = response.success;

        if (this.unsubscribed) {
          this.cookieService.delete(EMAIL_COOKIE_KEY);
        }
      },
      error: () => (this.unsubscribed = false),
      complete: () => (this.loading = false)
    });
  }

  openSubscribeDialog(event) {
    event.preventDefault();
    const ref = this.dialog.open(EmailSubscriberComponent, {
      width: '90%',
      maxWidth: '500px',
      panelClass: 'subscribe-dialog'
    });

    ref.componentInstance.subscribed
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        ref.close();
        this.router.navigateByUrl('');
      });
  }
}
