import {
  Component,
  OnInit,
  forwardRef,
  Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  SimpleChanges,
  OnChanges,
  OnDestroy, inject, DestroyRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RadioService } from './radio.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'lib-radio-group',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    RadioService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioGroupComponent),
      multi: true,
    },
  ],
})
export class RadioGroupComponent implements OnChanges, OnInit, ControlValueAccessor, OnDestroy
{
  private destroyRef = inject(DestroyRef);
  @Input() disabled = false;
  @Input() name: string | null;

  value: any;

  onChange = (value: any) => {};
  onTouched = () => {};

  ngOnChanges(changes: SimpleChanges): void {
    const { disabled, name } = changes;
    if (disabled) {
      this.radioService.setDisabled(this.disabled);
    }
    if (name) {
      this.radioService.setName(this.name);
    }
  }

  ngOnInit() {
    this.radioService.selected$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        if (this.value !== value) {
          this.value = value;
          this.onChange(this.value);
        }
      });

    this.radioService.touched$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      Promise.resolve().then(() => this.onTouched());
    });
  }

  ngOnDestroy() {}

  writeValue(value: any): void {
    this.value = value;
    this.radioService.select(value);
    this.cdr.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.radioService.setDisabled(isDisabled);
    this.cdr.markForCheck();
  }

  constructor(
    private radioService: RadioService,
    private cdr: ChangeDetectorRef
  ) {}
}
