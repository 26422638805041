import { Component, DestroyRef, inject, OnDestroy } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { OverlayService } from '@core/services';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '../environments/environment';
import { Meta } from '@angular/platform-browser';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'gc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnDestroy {
  private destroyRef = inject(DestroyRef);

  showcookieAlert: boolean;
  showOverlay: boolean;

  constructor(
    private cookieService: CookieService,
    private overlay: OverlayService,
    private router: Router,
    private gtmService: GoogleTagManagerService,
    private meta: Meta
  ) {
    this.showcookieAlert = !Boolean(this.cookieService.get('cookies-accepted'));

    if (environment.meta) {
      this.meta.addTag(environment.meta);
    }

    this.overlay.show$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(show => (this.showOverlay = show));

    if (environment.gtm) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          const gtmTag = {
            event: 'page',
            pageName: event.url
          };
          try {
            this.gtmService.pushTag(gtmTag);
          } catch (_e) {
            // do nothing
          }
        }
      });
    }
  }

  ngOnDestroy() {}

  saveCookieAcceptance() {
    this.cookieService.set('cookies-accepted', 'true');
    this.showcookieAlert = false;
  }
}
