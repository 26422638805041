import { Params } from '@angular/router';

export class LoadJobs {
  static readonly type = '[Jobs] LOAD';

  constructor(
    public readonly searchTerm: string,
    public readonly location: string,
    public readonly page = 1,
    public readonly params?: any
  ) {}
}

export class LoadSeletedJob {
  static readonly type = '[Jobs] LOAD SELECTED';

  constructor(public readonly id: number, public readonly jobReference: string, public readonly params: Params) {}
}

export class SubmitAnswers {
  static readonly type = '[Jobs] SUBMIT ANSWERS';

  constructor(
    public readonly id: number,
    public readonly answers,
    public readonly settings
  ) {}
}

export class SeletJob {
  static readonly type = '[Jobs] SELECT JOB';

  constructor(public readonly id: string) {}
}
