<div class="container-lg bg pb-2 mt-4">
  <h1 class="d-none d-sm-block py-2">GetGreatCareers Privacy Policy</h1>
  <h2 class="d-block d-sm-none py-2">GetGreatCareers Privacy Policy</h2>
  <b>(Revised and Effective – July 7, 2020)</b>

<div class="text">
  <p>Appcast, Inc. (“Appcast”, “we”, “us”, or “our”) wants you to benefit from our website,  www.getgreatcareers.com (the “Site”), and the services available on the Site (the “Service”) secure in the knowledge that we have implemented fair and transparent information policies and practices designed to protect your privacy.</p>
  <p>
    This Privacy Policy describes, among other things, (i) the types of information we collect when you are on the Site;
    (ii) how we use the information we collect; and (iii) with whom and why we share it. We also describe the measures we
    take to protect the security of the information and how to contact us about our privacy practices.
  </p>
  <p>
    This Privacy Policy does not apply to information collected by us offline or through any other means, including any
    other website owned or operated by Appcast or any third party.
  </p>
  <p>
    Please read this policy carefully to understand our policies and practices regarding your information and how we
    will treat it. By accessing and continuing to use the Service after having an opportunity to review this Privacy
    Policy, you accept the Privacy Policy in its entirety and without modification and consent to our collection, use,
    and sharing of the information as described herein. If you do not agree with this Privacy Policy, please do not use
    the Service.
  </p>
  <p>
    This policy may change from time to time (see Changes in Privacy Policy). Your continued use of this Website after
    we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.
  </p>
  <h2 class="my-2">
    Who Are We?
  </h2>
  Appcast is a Delaware corporation with offices in Lebanon, New Hampshire. We have listed contact information at the
  bottom of this policy should you have any questions about this Privacy Policy.
  <h2 class="my-2">
    Why Do We Collect Personal Data?
  </h2>
  We collect personal data about users of the Service to personalize and continually improve your experience on our Site
  and provide you and users like you with information about job opportunities, including:
  <ul>
    <li>providing you with information relating to job opportunities tailored to you, including by email as described
      further in the Emails section below;</li>
    <li>improving our services and operations; and</li>
    <li>performing statistical and demographic analyses of users of the Site and their behavioral patterns.</li>
  </ul>
<h2 class="my-2">What Information Do We Collect?</h2>
<h3 class="my-1">Information You Provide Us</h3>
WWhen you request information or otherwise contact us by completing a form available on the Site, you may choose to
disclose information about yourself, including your name and email address.
<h3 class="my-1">Information Collected Through Technology</h3>
Like many websites, we use web analytics tools that rely on cookies, web beacons, ID syncing, and other automated
tracking technologies (collectively, “Tracking Tools”) to help us analyze how users interact with and use the Service,
improve the Service, and personalize your experience on the Site. See below for a description of each:
<ul>
  <li><i>Cookies.</i> A cookie is a small data file that can be placed on your hard drive when you visit certain
    websites.
    Cookies are used to collect and store information, such as the type of computer you are using and how often you log
    on to a site. Our Site uses both “session cookies,” to improve your navigation across the Site and to remember you
    over the course of a single visit, and “persistent cookies,” which are stored on your computer until they expire or
    are deleted and help us provide better service to you over multiple visits to the Site. You can always delete or
    disable cookies through your browser. If you disable cookies, you may not be able to take advantage of certain
    features of the Site that require cookies.</li>
  <li><i>Web Beacons.</i> In addition to cookies, we deliver “web beacons” through our Site. Web beacons are small
    graphic
    images that may not be visible to Site visitors that collect information about Site usage on an anonymous basis. In
    some cases, anonymous data may be transmitted to third-party advertising networks. We use web beacons for our own
    internal analytics, to better understand the ways users interact with our website, to determine whether a particular
    email was received and opened, to determine whether a particular link to a job description was clicked on.</li>
  <li><i>ID Syncing.</i> ID syncing is a methodology for securely and privately making associations between two or more web
    servers about certain actions for attribution and ad optimization purposes.</li>
</ul>
For example, we may use these technologies to collect information when you click on a job link on the Site. The
information we collect in this manner includes anonymized IP address, browser type, device type, operating system,
referring URLs, dates and times of website visits, actions you take on such websites, as well as employment preferences
and general location for purposes of sending you emails containing information about job opportunities tailored to you.
<p>
The Tracking Tools we use include tools used in connection with services we obtain from third parties. The information
generated by analytics services will be transmitted to and stored by the third parties and will be subject to their
privacy policies. We do not control these third parties’ tracking technologies, how they may be used, or the information
they may collect, and we are not responsible for these third parties’ privacy policies or practices.
</p>
<p>
One of the third-party Tracking Tools we use is Google Analytics. The information generated by Google Analytics will be
transmitted to and stored by Google and will be subject to Google’s privacy policies, which can be viewed by <a
  target="_blank"
  href="https://policies.google.com/privacy?hl=en&gl=us"
>clicking
here</a> (“Welcome to the Google Privacy Notice”). To learn more about Google’s partner services and to learn how to opt
out
of tracking of analytics by Google, <a target="_blank" href="https://policies.google.com/privacy/partners">click here</a> (“How Google
uses data when you use our partners’ sites or apps”).
</p>
<p>
In addition, as part of the Service, we may provide links to third-party websites, including websites of employers (see
the “Links” section below). We do not control any third parties’ tracking technologies, how they may be used, or the
information they may collect, and we are not responsible for these third parties’ privacy policies.
</p>
Most web browsers automatically accept cookies, but browser settings can be changed to prevent these cookies from
collecting information. Disabling cookies does not necessarily disable the use of pixel tags, web beacons, and other
technologies that collect information.


<h3 class="my-1">Information from Third Parties and Other Sources:</h3>
We supplement the information we collect through the Site with information from third parties and information we collect
through other means, such as offline or through other websites, including those owned and operated by Appcast or a third
party.


<h2 class="my-2">How Do We Use the Information We Collect?</h2>
We use the information you provide to us for the following purposes:
<ul>
  <li>Providing the Service to you </li>
  <li>Providing you with information job opportunities, including by email as set forth in the Emails section below</li>
  <li>Responding to and communicating with you about your questions and comments</li>
  <li>Operating, evaluating, and improving our Service (including developing new services, managing our communications,
    and performing accounting, auditing, and other internal functions)</li>
  <li>Performing data analyses</li>
  <li>Complying with applicable legal requirements, relevant industry standards, and our policies</li>
  <li>Any other purpose about which we notify you</li>
</ul>

You acknowledge and agree that we own all of the information we collect from users of the Service, including information
collected via Tracking Technologies. We may use such information for any purpose whatsoever in connection with our
business.

<h2 class="my-2">Who Do We Share Information With?</h2>
We do not sell or otherwise disclose the information we collect, except as described in this Privacy Policy or
otherwise disclosed when the information is provided. In no event will we publicly disclose any performance-related data
specific to you. We may share the information we collect in connection with the Service with the following:
<ul>
  <li><b>Service Providers: </b>We have service providers that perform functions on our behalf, including hosting,
    content
    management, and analytics. These entities may have access to personal data if necessary or useful to performance of
    their functions.</li>
  <li><b>Employers: </b>Appcast may share certain anonymized information relating to job advertisement performance with
    its
    employer-customers. </li>
  <li><b>Law Enforcement / Protection: </b>Appcast reserves the right to share information about you where Appcast
    believes
    that such disclosure is necessary to comply with a legal requirement, such as a subpoena or court order, or to
    protect the rights or property of Appcast, you, or third parties. We may also cooperate with law enforcement
    agencies in any official investigation and we may disclose information to the relevant agency in doing so.</li>
  <li><b>Corporate Transactions: </b>We reserve the right to share information in connection with any corporate
    transaction,
    including any merger, consolidation, restructuring, sale of stock and/or assets, or other corporate change or
    financing transaction, whether during the course of any due diligence process or otherwise.
</li>
  <li><b>Consent: </b>Appcast may share your information in any other manner to which you consent.</li>
</ul>

<h2 class="my-2">Data Storage and Security</h2>
Information security is important to us. We use reasonable efforts to guard your personal data against accidental loss
and from unauthorized access, use, alteration, and disclosure. We update and test our security technology on an ongoing
basis. We restrict access to your personal data to those Appcast staff who need to know that information to provide
benefits or services to you. In addition, we train our Appcast staff about the importance of confidentiality and
maintaining the privacy and security of your information.
<p>
Although we take reasonable precautions to protect your information, no website or Internet transmission is completely
secure, and we cannot guarantee that unauthorized access, hacking, data loss, or other breaches will never occur. Any
transmission of personal information to or using our Service is at your own risk. We are not responsible for
circumvention of any privacy settings or security measures contained in the Service.
</p>

<h2 class="my-2">Do Not Track</h2>
We adhere to the standards set forth in this Privacy Policy and do not monitor or respond to Do Not Track requests.
<h2 class="my-2">What Are Your Choices About Your Personal Data?</h2>
Appcast provides you choices regarding the way we use the personal data you have provided us. For example, you may
revoke your consent to receive emails as set forth in the Emails section below.
<p>
If you would like to access, review, update, rectify, and delete any personal information we hold about you, or exercise
any other data subject right available to you under applicable law, you can either fill out <a href="http://appcast.io/california-consumer-privacy-act"
>this webform </a>directly or
call us at <b><a href="tel:+18005705430">+1-800-570-5430</a>, extension 4.</b> Our privacy team will examine your request
and respond to you as quickly as possible.
</p>
<p>
Please note that we may still use any aggregated and deidentified personal information that does not identify any
individual, and may also retain and use your information as necessary to comply with our legal obligations, resolve
disputes, complete transactions for which the personal information was collected, and enforce our agreements.
</p>
<p>
California residents are entitled to ask us for a notice identifying the categories of personal information which we
share with our affiliates and/or third parties for marketing purposes and providing contact information for such
affiliates and/or third parties. If you are a California resident and would like a copy of this notice, please submit a
written request via our <a href="http://appcast.io/california-consumer-privacy-act">webform</a>.
</p>

<h2 class="my-2">Emails</h2>
On the Site, you may provide your email address and other information to receive email notifications about potential job
opportunities that may be of interest.
<p>
You have the right to revoke this consent at any time for future emails. You may opt out of receiving these
communications at any time by following the instructions set forth in any email that we send you or by contacting us at
<a href="email:contact@getgreatcareers.com"></a>.
</p>

<h2 class="my-2">Transferring Personal Data to The United States</h2>
Appcast is located in the United States. Please be aware that any information you provide to us may be transferred to
and processed in the United States and be subject to United States law. The privacy and data protection laws in the
United States may not be equivalent to the laws in your country of residence.
<p>
The United States has not sought nor received a finding of “adequacy” from the European Union under Article 45 of the
GDPR. Appcast relies on derogations for specific situations as set forth in Article 49 of the GDPR. In particular,
Appcast collects and transfers to the U.S. personal data only: with your consent, to perform a contract with you, or to
fulfill a legitimate interest of Appcast in a manner that does not outweigh your rights and freedoms. Appcast endeavors
to apply suitable safeguards to protect the privacy and security of your personal data and to use it only consistent
with your relationship with Appcast and the practices described in this Privacy Policy.
</p>

<h2 class="my-2">Links</h2>
This Privacy Policy applies only to information collected by the Site and not to other websites that you may link to
from the Site, including other websites owned or operated by Appcast. Appcast is not responsible for the privacy
policies or practices of third-party sites, and other sites owned by Appcast may be subject to different privacy
policies and practices. Please be aware that when you click on a job ad, you will leave the Site and you should read the
privacy policies of the websites you reach.

<h2 class="my-2">Changes in Privacy Policy</h2>
From time to time we may update this Privacy Policy and will post a notice of any significant changes on our website.
You are responsible for periodically reviewing this Privacy Policy and your continued use of the Service will be subject
to the terms of the then-current Privacy Policy. You can see when this Privacy Policy was last updated by checking the
date at the top of this page.

<h2 class="my-2">Governing Law</h2>
This Privacy Policy and your use of the Service will be governed by and construed in accordance with the laws of the
State of New Hampshire, without giving effect to its conflicts of law provisions. By accessing or using the Service, you
consent and submit to the exclusive jurisdiction and venue of the state and federal courts located in New Hampshire. A
printed version of this Privacy Policy will be admissible in judicial and administrative proceedings based upon or
relating to this Privacy Policy to the same extent and subject to the same conditions as other business documents and
records originally generated and maintained in printed form.

<h2 class="my-2">Enforceability; Non-waiver</h2>
In the event that any provision of this Privacy Policy conflicts with applicable law, rule, regulation or order or if
any provision is held invalid by a court with competent jurisdiction, then such provision will be deemed to be restated
to reflect as nearly as possible our original intentions, and the remainder of this Privacy Policy will remain in full
force and effect. Any failure by us to insist upon or enforce strict performance of any provision of the Privacy Policy
or to exercise any right under the Privacy Policy will not be construed as a waiver or relinquishment of our right to
assert or rely upon any such provision or right in that or any other instance, rather, the same will be and remain in
full force and effect.

<h2 class="my-2">Contact</h2>
If you have any questions about this Privacy Policy, you may contact us at <a
  href="email:contact@getgreatcareers.com">contact&#64;getgreatcareers.com</a>.

  <p>
    Copyright © 2021 Appcast, Inc. All rights reserved.
  </p>
</div>
</div>
