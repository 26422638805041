import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { pluck } from 'rxjs/operators';

@Injectable()
export class LocationService {
  constructor(private httpClient: HttpClient) {}

  searchLocations(keyword: string): Observable<{ address: string }[]> {
    if (!keyword) {
      return of([]);
    }

    return this.httpClient.get<{ address: string }[]>('/locations', {
      params: {
        term: keyword
      }
    });
  }

  getUserLocation(): Observable<string> {
    return this.httpClient
      .get<{ ipLocation: string }>(`/job_search/ip_location`)
      .pipe(pluck('ipLocation'));
  }
}
