<header>
  <div class="content">
    <div class="job-logo">
      <img src="{{logo}}" alt="logo" *ngIf="logo">
      <img src="assets/default-job-logo.svg" class="default-logo" alt="logo" *ngIf="!logo">
    </div>
    <div class="job-name">
      {{title}}
    </div>
    <ul class="job-info">
      <li
        class="job-info__item"
        *ngIf="companyName"
      >
        <i class="icon-company"></i>
        <div class="job-info__text">{{companyName}}</div>
      </li>
      <li
        class="job-info__item"
        *ngIf="location"
      >
        <i class="icon-location"></i>
        <div class="job-info__text">{{location}}</div>
      </li>
      <li
        class="job-info__item"
        *ngIf="employment"
      >
        <i class="icon-time"></i>
        <div class="job-info__text">{{employment}}</div>
      </li>
    </ul>
  </div>
</header>
