import {
  Component,
  OnInit,
  HostListener,
  ElementRef,
  forwardRef,
  Input,
  ChangeDetectorRef,
  Inject,
  ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { GOOGLE_DRIVE_CREDENTIALS, ICredentials } from './google-drive.token';

@Component({
  selector: 'lib-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadComponent),
      multi: true
    }
  ]
})
export class UploadComponent implements OnInit, ControlValueAccessor {
  @Input() disabled = false;
  @Input() color: string;

  @ViewChild('fileInput')
  fileInput: ElementRef;

  showGoogle = true;
  showDropbox = true;
  showFile = true;

  file: File | null = null;
  fileName: string;
  googleDrive: GoogleDrive;
  onChange = _ => {};

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    const file = event && event.item(0);
    this.onChange({ file });
    this.file = file;
    this.fileName = file.name;
    this.showDropbox = this.showGoogle = false;
  }

  constructor(
    private host: ElementRef<HTMLInputElement>,
    private cdr: ChangeDetectorRef,
    @Inject(GOOGLE_DRIVE_CREDENTIALS) private credentials: ICredentials
  ) {}

  ngOnInit() {
    this.googleDrive = new GoogleDrive(
      this.credentials.apiKey,
      this.credentials.clientId,
      'id',
      (token, url, name) => {
        this.fileName = name;
        this.onChange({ google_file_url: url, google_auth_token: token });
        this.showDropbox = this.showFile = false;
        this.cdr.detectChanges();
      }
    );
  }

  openDropbox() {
    window['Dropbox'].choose({
      success: files => {
        this.fileName = files[0].name;
        this.onChange({ dropbox_file_url: files[0].link });
        this.showGoogle = this.showFile = false;
        this.cdr.detectChanges();
      },
      linkType: 'direct',
      multiselect: false,
      extensions: [
        '.pdf',
        '.doc',
        '.docx',
        '.html',
        '.odt',
        '.xml',
        '.epub',
        '.rtf',
        '.txt'
      ]
    });
  }

  openGD() {
    this.googleDrive.upload();
  }

  writeValue(value: null) {
    this.host.nativeElement.value = '';
    this.file = null;
  }

  clear() {
    this.onChange(null);
    this.fileName = null;
    this.file = null;
    if (this.fileInput?.nativeElement?.value) {
      this.fileInput.nativeElement.value = '';
    }

    this.showDropbox = this.showFile = this.showGoogle = true;
    this.cdr.detectChanges();
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {}

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }
}

class GoogleDrive {
  developerKey;
  clientId;
  pickerApiLoaded;
  oauthToken;
  scope;
  uploadCallback;
  id;

  constructor(apiKey, clientId, id, uploadCallback) {
    this.developerKey = apiKey;
    this.clientId = clientId;
    this.pickerApiLoaded = false;
    this.oauthToken = '';
    this.scope = ['https://www.googleapis.com/auth/drive.readonly'];
    this.uploadCallback = uploadCallback;
    this.id = id;
  }

  upload() {
    const gapi = window['gapi'];

    gapi.load('auth', this.onAuthApiLoad.bind(this));
    gapi.load('picker', this.onPickerApiLoad.bind(this));
  }

  onAuthApiLoad() {
    const gapi = window['gapi'];

    gapi.auth.authorize(
      {
        client_id: this.clientId,
        scope: this.scope,
        immediate: false
      },
      this.handleAuthResult.bind(this)
    );
  }

  onPickerApiLoad() {
    this.pickerApiLoaded = true;
  }

  private handleAuthResult(authResult) {
    if (authResult && !authResult.error) {
      this.oauthToken = authResult.access_token;
      return this.createPicker();
    }
  }

  private createPicker() {
    const google = window['google'];

    if (this.pickerApiLoaded && this.oauthToken) {
      const picker = new google.picker.PickerBuilder()
        .addView(google.picker.ViewId.PDFS)
        .addView(google.picker.ViewId.DOCUMENTS)
        .addView(google.picker.ViewId.FOLDERS)
        .setOAuthToken(this.oauthToken)
        .setDeveloperKey(this.developerKey)
        .setCallback(this.pickerCallback)
        .build();
      return picker.setVisible(true);
    }
  }

  private pickerCallback = data => {
    const google = window['google'];

    if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
      const doc = data[google.picker.Response.DOCUMENTS][0];
      const url = doc[google.picker.Document.URL];
      const name = doc[google.picker.Document.NAME];
      this.uploadCallback(this.oauthToken, url, name, this.id);
    }
  };
}
