<div class="search-form d-none d-sm-block">
  <ng-container
    [ngTemplateOutlet]="searchForm"
    [ngTemplateOutletContext]="{ className: 'd-flex flex-row' }"
  ></ng-container>
</div>

<div class="search-form search-form-column d-flex flex-column d-sm-none">
  <ng-container
    *ngIf="searchVisible"
    [ngTemplateOutlet]="searchForm"
    [ngTemplateOutletContext]="{ className: '' }"
  ></ng-container>

  <div
    class="form-row flex-sm-fill message"
    *ngIf="!searchVisible"
  >
    <div
      class="message-overflow"
      (click)="showSearchForm()"
    ></div>
    <label class="mb-2">Jobs</label>
    <lib-input [ngModel]="message"></lib-input>

    <ng-container [ngTemplateOutlet]="tooltip"></ng-container>
  </div>
</div>

<ng-template #tooltip>
  <div
    *ngIf="showLocationTooltip"
    class="tooltip"
  >
    Is this your location? If not, update now for the most accurate results!
    <span
      class="close icon-cross"
      (click)="showLocationTooltip = false"
    ></span>
  </div>
</ng-template>

<ng-template #searchForm let-className="className">
  <form
    (keydown.enter)="search()"
    class="{{className}}"
  >
  <div class="form-row flex-sm-fill">
    <label class="mb-2">Jobs</label>
    <gc-input
      [formControl]="keyword"
      [addon]="searchIcon"
      placeholder="Job titles, companies"
    ></gc-input>
  </div>
  <div class="form-row flex-sm-fill">
    <label class="mb-2">Location</label>
    <span class="location-tooltip-wrapper">
      <gc-input
        [formControl]="location"
        [addon]="locationIcon"
        [autocomplete]="auto"
        placeholder="City, State, or Zip Code"
      ></gc-input>
      <ng-container [ngTemplateOutlet]="tooltip"></ng-container>
    </span>
    
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="optionSelectedHandler($event)"
    >
      <mat-option
        *ngFor="let street of locations"
        [value]="street.address"
      >
        {{street.address}}
      </mat-option>
    </mat-autocomplete>
  </div>

  <div class="button-container align-self-sm-end">
    <lib-button
      class="find-jobs"
      [loading]="loading"
      (click)="search()"
    >Find jobs</lib-button>
  </div>
  </form>

  <ng-template #locationIcon>
    <img
      *ngIf="!location?.value"
      [class.location-loader]="locationLoading"
      [attr.title]="locationLoading ? 'detecting location' : ''"
      height="100%"
      src="./assets/location.svg"
    />
    <span
      *ngIf="location?.value"
      class="icon-cross"
      (click)="location.setValue(null)"
    ></span>
  </ng-template>

  <ng-template #searchIcon>
    <img
      *ngIf="!keyword?.value"
      width="100%"
      src="./assets/input_search.svg"
    />
    <span
      *ngIf="keyword?.value"
      class="icon-cross"
      (click)="keyword.setValue(null)"
    ></span>
  </ng-template>
</ng-template>
