import {
  Component,
  OnInit,
  forwardRef,
  Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Optional,
  OnDestroy,
  inject, DestroyRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RadioService } from './radio.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'lib-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonComponent),
      multi: true,
    },
  ],
})
export class RadioButtonComponent implements OnInit, ControlValueAccessor, OnDestroy {
  private destroyRef = inject(DestroyRef);

  @Input() disabled = false;
  @Input() value;

  isChecked = false;
  name: string | null = null;

  onChange = (value: any) => { };
  onTouched = () => { };

  writeValue(value: boolean): void {
    this.isChecked = value;
    this.cdr.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  constructor(private cdr: ChangeDetectorRef, @Optional() private radioService: RadioService) { }

  ngOnInit(): void {
    if (this.radioService) {
      this.radioService.name$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(name => {
        this.name = name;
        this.cdr.markForCheck();
      });
      this.radioService.disabled$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(disabled => {
        this.disabled = disabled;
        this.cdr.markForCheck();
      });
      this.radioService.selected$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
        this.isChecked = this.value === value;
        this.cdr.markForCheck();
      });
    }
  }

  ngOnDestroy() {}

  ckicked() {
    if (!this.disabled && !this.isChecked) {
      if (this.radioService) {
        this.radioService.select(this.value);
        this.radioService.touch();
      }

      this.isChecked = true;
      this.onChange(true);
      this.onTouched();
    }
  }
}
