import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';

@Component({
  selector: 'gc-popular-searches',
  templateUrl: './popular-searches.component.html',
  styleUrls: ['./popular-searches.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopularSearchesComponent implements OnInit {
  @Input() popularSearches: string[];
  @Output() termClicked = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
