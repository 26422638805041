
By accepting all cookies, you agree to the storing of cookies on your device to enhance site navigation, analyze
site usage, and assist in marketing efforts.
<lib-button
  class="mx-3"
  (libClick)="saveCookieAcceptance()"
>Accept</lib-button>
<a
  href="./privacy"
  target="_blank"
>
  Privacy Policy
</a>