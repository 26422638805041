<label
  class="radio-label"
  [class.radio-disabled]="disabled"
  (click)="ckicked()"
>
  <span 
    class="radio-wrapper"
    [class.radio-checked]="isChecked">
    <input
      type="radio"
      [name]="name"
      [value]="value"
      [checked]="isChecked"
    />
    <span class="radio-inner"></span>
  </span>
  <span>
    <ng-content></ng-content>
  </span>
</label>
