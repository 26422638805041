import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  TemplateRef
} from '@angular/core';
import { IJobViewModel, Question } from '@core/view-models';
import { environment } from '../../../environments/environment';
import { WindowRef } from '@core/services/window-ref';
declare let fbq: Function;

@Component({
  selector: 'gc-job-view',
  templateUrl: './job-view.component.html',
  styleUrls: ['./job-view.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobViewComponent implements OnInit, OnChanges {
  @Input() job: IJobViewModel;
  @Input() loading: boolean;
  @Input() applyButtonText = 'Apply';
  @Input() message: string | TemplateRef<any>;
  @Input() questions: Question[];
  @Input() submitted: boolean;
  @Output() backClick = new EventEmitter();
  @Output() formSubmit = new EventEmitter();
  isMessageTemplate: boolean;

  constructor(private cdr: ChangeDetectorRef, private window: WindowRef) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.job && changes.job.currentValue && !changes.job.firstChange) {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.cdr.detectChanges();
      }, 1000);
    }

    if (changes.message) {
      this.isMessageTemplate = this.message instanceof TemplateRef;
    }
  }

  goToJob() {
    if (!this.haveNoQuestions()) {
      const btn: HTMLElement = document.querySelector('.buttons-container .submit-btn button');
      btn.click();
    }
  }

  submit(form) {
    if (this.haveNoQuestions()) {
      return;
    }

    if (form.invalid) {
      return;
    }

    this.formSubmit.emit(form);
  }

  haveNoQuestions() {
    if ((!this.questions || !this.questions.length) && this.job.url) {
      if (environment.useFacebookPixel) {
        fbq('trackCustom', 'job_click');
      }
      this.window.nativeWindow.open(this.job.url);

      return true;
    }

    return false;
  }
}
