import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'gc-accept-cookie',
  templateUrl: './accept-cookie.component.html',
  styleUrls: ['./accept-cookie.component.less']
})
export class AcceptCookieComponent implements OnInit {
  @Output() accepted = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  saveCookieAcceptance() {
    this.accepted.emit(true);
  }
}
