import { Component, OnInit, HostBinding, AfterViewInit, Self, ElementRef, Input, OnDestroy, inject, DestroyRef } from '@angular/core';

import { from, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'lib-expandable-block',
  templateUrl: './expandable-block.component.html',
  styleUrls: ['./expandable-block.component.less'],
})
export class ExpandableBlockComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroyRef = inject(DestroyRef);

  @Input() maxHeight: number;

  showFade: boolean;
  fullDescription = false;

  @HostBinding('class.preview')
  get preview() {
    return this.showFade && !this.fullDescription;
  }

  @HostBinding('style.height')
  get height() {
    return !this.showFade || this.fullDescription ? 'fit-content' : `${this.maxHeight}px`;
  }

  @HostBinding('class.full')
  get full() {
    return this.showFade && this.fullDescription;
  }

  constructor(@Self() private element: ElementRef) { }

  ngAfterViewInit(): void {
    setTimeout(() => this.recalculateFadeVisibility(), 0);
  }

  ngOnInit(): void {
    fromEvent(window, 'resize')
      .pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
      .subscribe(_ => {
        this.recalculateFadeVisibility();
      });
  }

  ngOnDestroy(): void {}

  showFull() {
    this.fullDescription = !this.fullDescription;
  }

  private recalculateFadeVisibility(): void {
    this.showFade = this.maxHeight && this.element.nativeElement.scrollHeight > this.maxHeight;
  }
}
