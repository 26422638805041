import { InjectionToken } from '@angular/core';

export interface ICredentials {
  apiKey: string;
  clientId: string;
}

export const GOOGLE_DRIVE_CREDENTIALS = new InjectionToken<ICredentials>('MyToken', {
  factory: () => ({
    apiKey: 'AIzaSyCf3Iwrge0cRXHbGReKJOQ7mjT-Hc1MnRM',
    clientId: '442457156099-fdthk74viutbuqnp5s55lhu1ljheb5gr.apps.googleusercontent.com'
  })
});
