import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from './components/input/input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JobsListComponent } from './components/jobs-list/jobs-list.component';
import { JobsTileComponent } from './components/jobs-tile/jobs-tile.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SearchBoxComponent } from './components/search-box/search-box.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { JobViewComponent } from './components/job-view/job-view.component';
import { ContinueDialogComponent, PopularSearchesComponent } from './components';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NativeAdComponent } from './components/native-ad/native-ad.component';
import { EmailSubscribeFormComponent } from './components/email-subscribe-form/email-subscribe-form.component';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'src/common/lib/button/button.module';
import { InputModule } from 'src/common/lib/input/input.module';
import { HeaderModule } from 'src/common/lib/header/header.module';
import { ExpandableBlockModule } from 'src/common/lib/expandable-block/expandable-block.module';
import { QuestionsModule } from 'src/common/lib/questions/questions.module';
import { CardModule } from 'src/common/lib/card/card.module';
import { QuickApplyModule } from 'src/common/lib/quick-apply/quick-apply.module';
import { QuestionControlService } from 'src/common/services';
import { ControlErrorsModule } from 'src/common/lib/control-errors/control-errors.module';


@NgModule({
  declarations: [
    InputComponent,
    JobsListComponent,
    JobsTileComponent,
    SearchBoxComponent,
    JobViewComponent,
    ContinueDialogComponent,
    EmailSubscribeFormComponent,
    PopularSearchesComponent,
    NativeAdComponent,
  ],
  providers: [
    QuestionControlService,
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 2500,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['notification']
      }
    },
    QuestionControlService
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    NgxPaginationModule,
    ButtonModule,
    InputModule,
    HeaderModule,
    ExpandableBlockModule,
    QuestionsModule,
    CardModule,
    QuickApplyModule,
    MatTooltipModule,
    MatDialogModule,
    NgxSkeletonLoaderModule,
    RouterModule,
    MatSnackBarModule,
    ControlErrorsModule.forRoot({
      'required-radio': () =>
        'A selection must be made to continue to the application'
    })
  ],
  exports: [
    InputComponent,
    FormsModule,
    JobsListComponent,
    MatAutocompleteModule,
    SearchBoxComponent,
    ButtonModule,
    InputModule,
    HeaderModule,
    JobViewComponent,
    ContinueDialogComponent,
    MatDialogModule,
    NgxSkeletonLoaderModule,
    MatTooltipModule,
    EmailSubscribeFormComponent,
    MatSnackBarModule,
    PopularSearchesComponent,
    CardModule,
  ]
})
export class SharedModule {}
