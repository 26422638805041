import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WindowRef } from './window-ref';
import { CookieService } from 'ngx-cookie-service';
import { tap, map, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

@Injectable()
export class AuthService {
  constructor(
    private http: HttpClient,
    private window: WindowRef,
    private cookieService: CookieService
  ) {}

  login(username: string, password: string) {
    const userToken = this.window.nativeWindow.btoa(`${username}:${password}`);
    return this.http
      .get('/api/job_subscriptions', {
        headers: { Authorization: `Basic ${userToken}` }
      })
      .pipe(
        tap(() => {
          this.cookieService.set('u_data', userToken, 1);
        })
      );
  }

  check(token: string): Observable<boolean> {
    return this.http
      .get('/api/job_subscriptions', {
        headers: { Authorization: `Basic ${token}` }
      })
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }
}
