import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class PopularSearchesProvider {
  constructor(private httpClient: HttpClient) {}

  getPopularSearches(): Observable<string[]> {
    return of([
      'Warehouse',
      'Driver',
      'Healthcare',
      'Sales',
      'Part-time',
      'Full-time'
    ]);
    // should be chaged as soos as api is ready
    return this.httpClient.get<string[]>('/api/popular-searches');
  }
}
