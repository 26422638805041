<form id="questions-form" [skip]="skipValidation">
  <span
    *ngIf="indeed"
    class="indeed-apply-widget"
    [attr.data-indeed-apply-apitoken]="indeed.apiToken"
    [attr.data-indeed-apply-jobtitle]="indeed.jobTitle"
    [attr.data-indeed-apply-jobId]="indeed.jobId"
    [attr.data-indeed-apply-jobCompanyName]="indeed.jobCompanyName"
    [attr.data-indeed-apply-jobMeta]="indeed.jobMeta"
    [attr.data-indeed-apply-phone]="indeed.phone"
    [attr.data-indeed-apply-onapplied]="indeedApplyCallback"
    [attr.data-indeed-apply-continueUrl]="indeed.continueUrl"
    [attr.data-indeed-apply-coverletter]="indeed.coverLetter"
    [attr.data-indeed-apply-postUrl]="indeed.postUrl"
    [attr.data-indeed-apply-questions]="indeed.questions"
    [attr.data-indeed-apply-skipcontinue]="true"
    [attr.data-indeed-apply-noButtonUI]="true"
  >
    <lib-button color="accent">Apply With Indeed</lib-button>
  </span>
  <ng-content *ngIf="questions?.length"></ng-content>
  <ol
    *ngIf="form"
    class="questions-container"
    [formGroup]="form"
  >
    <ng-container
      *ngFor="let question of questions; let i = index;">
        <div
          class="page-break"
          *ngIf="i > 0 && question.groupId !== questions[i - 1].groupId">
        </div>

        <li
          class="question"
          [class.group-question]="question.groupId"
          *ngIf="question.controlType !== controlType.info && question.controlType !== controlType.consent && !conditionDeclined(question)"
        >
          <p class="question_text" [class.required]="question.required">
            {{ question.text }}
          </p>

          <div [ngSwitch]="question.controlType">

            <lib-input
              *ngSwitchCase="controlType.textbox"
              class="question_control"
              [formControlName]="question.id"
              placeholder=" "
              type="text"
            ></lib-input>

            <lib-input
              *ngSwitchCase="controlType.number"
              class="question_control"
              [formControlName]="question.id"
              placeholder=" "
            ></lib-input>

            <app-datepicker
              *ngSwitchCase="controlType.date"
              class="question_control"
              [formControlName]="question.id"
            ></app-datepicker>

            <lib-input
              *ngSwitchCase="controlType.phone"
              class="question_control"
              [formControlName]="question.id"
              placeholder="(XXX) XXX-XXXX or +X XXX XXX XXXX"
            ></lib-input>

            <lib-radio-group
              *ngSwitchCase="controlType.radio"
              [name]="question.id"
              [formControlName]="question.id"
              messagePrefix="radio"
            >
              <lib-radio-button
                *ngFor="let option of question.options"
                [value]="option"
              >{{option}}</lib-radio-button>
            </lib-radio-group>

            <ng-container *ngSwitchCase="controlType.dropdown">
              <lib-dropdown
                class="question_control"
                [name]="question.id"
                [formControlName]="question.id"
                [options]="question.options"
              >

              </lib-dropdown>
            </ng-container>

            <ng-container
              *ngSwitchCase="controlType.checkbox"
            >
              <div [formArrayName]="question.id" controlErrorContainer>
                <lib-checkbox
                  *ngFor="let option of question.options; index as i"
                  class="m-b-x"
                  [formControlName]="i"
                >{{ option }}</lib-checkbox>

              </div>
            </ng-container>

            <textarea
              [formControlName]="question.id"
              class="question_control"
              placeholder=" "
              *ngSwitchCase="controlType.textarea"
            >
            </textarea>

            <lib-upload
              class="question_control"
              *ngSwitchCase="controlType.upload"
              [color]="color"
              [formControlName]="question.id"
            ></lib-upload>

          </div>

        </li>

        <div
          class="question"
          [class.group-title]="i > 0 && question.groupId !== questions[i - 1].groupId"
          *ngIf="question.controlType === controlType.info || question.controlType === controlType.consent">
            <p class="question_text">
              {{ question.text }}
            </p>
        </div>

    </ng-container>

  </ol>
  <div class="buttons-container">
    <lib-button
      (libClick)="formSubmitted.emit(form)"
      [loading]="loading"
      [attr.stroked]="color"
      [color]="color"
      class="submit-btn"
    >{{applyButtonText}}</lib-button>
  </div>
</form>
