import { Component, OnInit, ChangeDetectorRef, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    },
  ],
})

export class DatePickerComponent implements OnInit, ControlValueAccessor {

  selectedClass = '';

  displayValue: string;
  private value: string;

  change = (_: any) => { };
  touch = () => { };

  constructor(private cdr: ChangeDetectorRef) { }

  writeValue(obj: any) {
    this.setValue(obj, false);
    this.cdr.detectChanges();
  }

  registerOnChange(fn: any) {
    this.change = fn;
  }

  registerOnTouched(fn: any) {
    this.touch = fn;
  }

  ngOnInit() { }

  modelChanged(value): void {
    this.setValue(value);
  }

  private setValue(value, emit = true): void {
    this.value = new Date(value).toLocaleString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' });
    this.displayValue = value;

    if (value &&!this.selectedClass) {
      this.selectedClass = 'filled';
    }

    if (emit) {
      this.change(this.value);
      this.touch();
    }
  }
}
