<footer class="container-fluid d-flex flex-column align-items-center bg py-4 px-4">
  <div class="d-flex align-self-stretch flex-row flex-sm-column justify-content-between">
    <div class="d-flex align-items-center justify-content-sm-center flex-column flex-sm-row">
      <a
        class="mr-sm-3"
        [routerLink]="['./about']"
      >About us</a>
      <a [routerLink]="['./']">Browse Jobs</a>
    </div>
    <div class="d-flex align-items-center justify-content-sm-center flex-column flex-sm-row">
      <a
        class="mr-sm-3"
        [routerLink]="['./privacy']"
      >Privacy policy</a>
      <a
        class="mr-sm-3"
        [routerLink]="['./terms-of-use']"
      >Terms of use</a>
      <a
        class="mr-sm-3"
        href="mailto:contact@getgreatcareers.com"
      >Contact us</a>
    </div>
  </div>
  © 2021 Get Great Careers
</footer>
