import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lib-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {
  @Input() title: string;
  @Input() companyName: string;
  @Input() location: string;
  @Input() employment: string;
  @Input() logo: string;

  constructor() { }

  ngOnInit(): void {
  }

}
