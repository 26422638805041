import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioButtonComponent } from './radio-button.component';
import { FormsModule } from '@angular/forms';
import { RadioGroupComponent } from './radio-button-group.component';



@NgModule({
  declarations: [ RadioButtonComponent, RadioGroupComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [ RadioButtonComponent, RadioGroupComponent]
})
export class RadioButtonModule { }
