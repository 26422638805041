<div
  class="d-flex align-items-end"
  *ngIf="isPipelineEnabled"
>
  <ng-container *ngIf="pipeline">
    <div
      class="d-none d-sm-block mr-4"
      *ngIf="pipeline?.logo"
    ><img
        [src]="pipeline?.logo"
        alt="{{pipeline.employerName}}"
      ></div>
    <div>
      <h3>{{pipeline.employerName}}</h3>
      <div class="text mt-2">Jobs For You</div>
      <div
        class="text mt-2"
        *ngIf="pipeline.landingPageText"
      >{{pipeline.landingPageText}}</div>
    </div>
    <div
      class="ml-5"
      *ngIf="showRedirectToEmloyerPage"
    ><a
        [routerLink]="'./'"
        [queryParams]="{e: pipeline.employerId, jg: null, location: null}"
        queryParamsHandling="merge"
      >See all <strong>{{pipeline.jobsCount}}</strong> jobs for {{pipeline.employerName}}</a></div>
  </ng-container>
</div>
