export interface Job {
  questions: Question[];
  brandColor: string;
  thankYouPageBody: string;
}

// eslint-disable-next-line
export enum QuestionTypeEnum {
  user_name = 10,
  email = 1,
  phone = 9,
  resume = 8,
   // eslint-disable-next-line
  string = 0,
  file = 2,
  text = 3,
  dropdown = 4,
  radio_buttons = 5,
  check_boxes = 6,
  zip = 7,
  location_confirmation = 11,
   // eslint-disable-next-line
  number = 12,
  date = 13,
  info = 14,
  consent = 15,
  first_name = 16,
  last_name = 17
}

// eslint-disable-next-line
export enum QuestionControlTypeEnum {
  textbox,
  textarea,
   // eslint-disable-next-line
  number,
  dropdown,
  radio,
  checkbox,
  upload,
  consent,
  info,
  phone,
  date
}

export interface Question {
  type: QuestionTypeEnum;
  controlType: QuestionControlTypeEnum;
  id: number;
  required: boolean;
  text: string;
  options?: string[];
  knockoutAnswers: string[];
  value?: any;
  priority: number;
  groupId?: string;
  minLength?: number | null;
  maxLength?: number | null;
  condition: {
    value: string;
    id: string | number;
    valueIndex?: number;
  };
}
