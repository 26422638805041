import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { IJobViewModel } from '@core/view-models';
import { registerLocaleData } from '@angular/common';

@Component({
  selector: 'gc-jobs-list',
  templateUrl: './jobs-list.component.html',
  styleUrls: ['./jobs-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobsListComponent implements OnInit, OnChanges {
  @Input() loading: boolean;
  @Input() jobs: IJobViewModel[];
  @Input() jobSelectedId: string;
  @Input() pagesInfo: { currentPage: number; total: number; perPage: number };
  @Input() keyword = '';
  @Input() location = '';

  @Output() pageChange = new EventEmitter();
  @Output() jobClicked = new EventEmitter();
  currentPage = 1;
  showAd = false;

  constructor(private cdr: ChangeDetectorRef) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.jobs && this.jobs && this.jobs.length) {
      setTimeout(() => {
        this.showAd = true;
        this.cdr.markForCheck();
      }, 500);
    }
  }

  ngOnInit(): void {}

  setPage(page: number): void {
    this.currentPage = page;
    this.pageChange.emit(page);
  }

  jobClickHandler(job: IJobViewModel) {
    this.jobSelectedId = job.id;
    this.jobClicked.emit(job);
  }
}
