<div class="tile bg" *ngIf="job">
  <!-- TODO: Move to separate component tag -->
  <span
    class="tag"
    matTooltip="Front Runner: Be among the first 10 applicants to submit an application!"
    matTooltipClass="custom-tooltip"
    #fronttooltip="matTooltip"
    (click)="$event.stopPropagation(); fronttooltip.toggle()"
    *ngIf="job.isFrontRunner"
  >front runner</span>
  <span
    class="tag light"
    matTooltip="Finish this application in less than 5 minutes!"
    matTooltipClass="custom-tooltip"
    #applytooltip="matTooltip"
    (click)="$event.stopPropagation(); applytooltip.toggle()"
    *ngIf="job.isQuickApply"
  >quick apply</span>
  <div class="title mb-1">
    {{job.title}}
  </div>
  <div class="campaign">
    <i class="icon-company mr-1"></i>
    {{job.campaign.title }}
  </div>
  <div class="location">
    <i class="icon-location mr-1"></i>
    {{job.address }}
  </div>

  <div class="posted"><span class="mr-2">{{ job.posted }}</span><span>More...</span></div>
</div>
