export const EXPIRED_JOB_DESCRIPTION =
  'Sorry, this job has expired. Check out these other great job opportunities!';

export const KNOCKOUT_NOT_FIT_QUESTIONS_DESCRIPTION = 
  'Based on your answers, this employer is looking for slightly different qualifications';

export const DEFAULT_THANKS_PAGE =
  'Thanks! Your application has been submitted!';

export const EMAIL_COOKIE_KEY = 'em_al';

export enum EmailActivationType {
  search = 'ggc_search',
  organic = 'ggc_job_show'
}

export const emailRegex = /^[a-zA-Z0-9.-_]+@[a-zA-Z0-9.-]+\.[a-zA-z]{2,}$/;
