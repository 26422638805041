import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gc-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.less']
})
export class TermsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
