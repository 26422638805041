<div class="buttons-container">
  <label
    *ngIf="showFile"
    class="upload-btn"
    [class.stroked]="color"
    [style.border-color]="color"
    [style.color]="color"
    [attr.disabled]="disabled ? true : null"
  >
    <input
      #fileInput
      type="file"
      accept=".pdf, .doc, .docx, .html, .odt, .xml, .epub, .rtf, .txt"
      [disabled]="disabled"
    >

    <span>Upload</span>
  </label>
  <span
    *ngIf="showDropbox"
    class="upload-btn"
    [class.stroked]="color"
    [style.border-color]="color"
    [style.color]="color"
    [attr.disabled]="disabled ? true : null"
    (click)="!disabled && openDropbox()"
  >
    <i class="icon-dropbox"></i>Dropbox
  </span>

  <span
    *ngIf="showGoogle"
    class="upload-btn"
    [class.stroked]="color"
    [style.border-color]="color"
    [style.color]="color"
    [attr.disabled]="disabled ? true : null"
    (click)="!disabled && openGD()">
    <i class="icon-googledrive"></i>Google Drive
  </span>
</div>

<div
  *ngIf="fileName"
  class="file-container">
    <div
      class="file-name"
      title="{{fileName}}"
    >
      {{fileName}}
    </div>
    <i class="remove-file icon-cross" (click)="clear()"></i>
</div>
