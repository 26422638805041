import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
declare let fbq: Function;

@Injectable()
export class SubscriptionProvider {
  constructor(private http: HttpClient) {}

  subscribeToReceiveEmails(
    email: string,
    location: string,
    keyword: string,
    type = 'ggc_search',
    recaptchaToken: string
  ): Observable<any> {
    return this.http
      .post('/api/job_subscriptions/subscribe', {
        email,
        search_location: location,
        search_keyword: keyword,
        activation_type: type,
        'g-recaptcha-response': recaptchaToken
      })
      .pipe(
        tap(() => {
          if (environment.useFacebookPixel) {
            fbq('trackCustom', 'email_submit');
          }
        })
      );
  }

  unsubscribeFromEmails(token: string): Observable<{ success: boolean }> {
    return this.http.get<{ success }>('/api/job_subscriptions/unsubscribe', {
      params: { t: token }
    });
  }

  adminUnsubscribeFromEmails(email: string): Observable<{ success: boolean }> {
    return this.http.post<{ success }>(
      '/api/job_subscriptions/unsubscribe_by_email',
      {
        email
      }
    );
  }
}
