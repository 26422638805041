
  <select
    [name]="name"
    [(ngModel)]="selectedValue"
    (ngModelChange)="valueChanged($event)"
    [disabled]="disabled"
  >
    <option
      *ngFor="let option of innerOptions"
      [value]="option.value"
    >{{ option.label }}</option>
  </select>
  <div class="select-icon"><i class="icon-arrow-down"></i></div>