import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from '../../services/question-control.service';
import { Question, QuestionControlTypeEnum } from '../../models/job.model';
import { IJobViewModel } from '@core/view-models';


@Component({
  selector: 'lib-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.less']
})
export class QuestionsComponent implements OnInit, OnChanges {
  @Input() questions: Question[];
  @Input() applyButtonText: string;
  @Input() indeed: any;
  @Input() job: IJobViewModel;
  @Input() loading: boolean;
  @Input() color: string;
  @Input() skipValidation: boolean;

  @Output() formSubmitted = new EventEmitter();

  form: UntypedFormGroup;

  controlType = QuestionControlTypeEnum;

  constructor(
    private questionControlService: QuestionControlService
  ) {
  }

  loadIndeed() {
    ((d, s, id) => {
      let js: any;
      const iajs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      // eslint-disable-next-line
      js = d.createElement(s);
      js.id = id;
      js.async = true;
      js.src = 'https://apply.indeed.com/indeedapply/static/scripts/app/bootstrap.js';
      iajs.parentNode.insertBefore(js, iajs);
    })(document, 'script', 'indeed-apply-js');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loading && this.form) {
      if (this.loading) {
        this.form.disable();
      } else {
        this.form.enable();
      }
    }

    if (changes.indeed && this.indeed) {
      this.loadIndeed();
    }
  }

  ngOnInit(): void {
    this.buildControls();
  }

  buildControls() {
    this.form = this.questionControlService.toFormGroup(this.questions);
  }

  indeedApplyCallback() {
    window.location.href = (window.location.href.split('?')[0] += '/indeed_submitted?indeed=1');
  }

  conditionDeclined(question) {
    if (!question.condition) {
      return false;
    }

    if (this.form.value[question.condition.id] instanceof Array) {
      // eslint-disable-next-line
      return question.condition.valueIndex == null || this.form.value[question.condition.id][question.condition.valueIndex] == false;
    }

    return this.form.value[question.condition.id] !== question.condition.value;
  }
}
