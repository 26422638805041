<form (submit)="formSubmit()">
  <h3 class="mb-3">
    Be A Front Runner for <strong>{{keyword}}</strong> jobs in <span>{{location || 'your area'}}!</span>
  </h3>

  <div class="desc mb-3">
    Enter your email below to be the first to know when new <span class="keyword">{{keyword}}</span>
      jobs are posted<span *ngIf="location"> in <span class="location">{{location}}</span></span>.
  </div>

  <div class="d-flex flex-column mb-3">
    <label class="mb-2">Email</label>
    <gc-input
      [formControl]="emailControl"
      (click)="showFields = true"
      (gcFocus)="showFields = true"
      placeholder="Enter your email to be notified"
    ></gc-input>
    <span
      class="error mt-1"
      *ngIf="emailControl.hasError('pattern')"
    >{{emailControl.getError('pattern')}}</span>
    <span
      class="error mt-1"
      *ngIf="emailControl.hasError('required')"
    >{{emailControl.getError('required')}}</span>
  </div>

  <div class="d-block d-sm-none" *ngIf="showFields">
    <div class="d-flex flex-column mb-3">
      <label class="mb-2">Keyword</label>
      <gc-input
        [formControl]="keywordControl"
        [addon]="tickKeywordTemplate"
        placeholder="keyword"
      ></gc-input>

    </div>

    <div class="d-flex flex-column">
      <label class="mb-2">Location</label>
      <gc-input
        [formControl]="locationControl"
        [addon]="tickLocationTemplate"
        placeholder="location"
      ></gc-input>
      <span
        class="error mt-1"
        *ngIf="locationControl.hasError('required')"
      >{{locationControl.getError('required')}}</span>

    </div>
  </div>

  <div class="d-none d-sm-block">
    <div class="d-flex flex-column mb-3">
      <label class="mb-2">Keyword</label>
      <gc-input
        [formControl]="keywordControl"
        [addon]="tickKeywordTemplate"
        placeholder="keyword"
      ></gc-input>

    </div>

    <div class="d-flex flex-column">
      <label class="mb-2">Location</label>
      <gc-input
        [formControl]="locationControl"
        [addon]="tickLocationTemplate"
        placeholder="location"
      ></gc-input>
      <span
        class="error mt-1"
        *ngIf="locationControl.hasError('required')"
      >{{locationControl.getError('required')}}</span>

    </div>
  </div>

  <ng-template #tickLocationTemplate>
    <span
      *ngIf="locationControl?.value"
      class="filled-in"
    ><div class="tick"></div></span>
  </ng-template>

  <ng-template #tickKeywordTemplate>
    <span
      *ngIf="keywordControl?.value"
      class="filled-in"
    ><div class="tick"></div></span>
  </ng-template>

  <div class="d-flex flex-column flex-start mt-3">
    <lib-button stroked [loading]="loading">Set Alert</lib-button>
    <div class="note mt-2 d-flex align-items-center">
      <img
        class="icon mr-1"
        [matTooltipPosition]="'above'"
        #fronttooltip="matTooltip"
        (click)="$event.stopPropagation(); fronttooltip.toggle()"
        matTooltip="Why do we collect this information? We ask for your email address so we can send you the alerts
        you’re opting in to receive. We also store the job types you’re looking for and the geographic area you’re
        looking in, so we can send you alerts for jobs that are of interest to you and in your target area."
        matTooltipClass="custom-tooltip"
        src="../assets/lnr-lock.svg"
      >
      <span>Enter your email address to receive job alert emails from us (you can unsubscribe at any time). Please see our
      <a [routerLink]="['/privacy']">Privacy Policy</a> for more information.</span></div>
  </div>
</form>