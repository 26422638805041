import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const emailValidator = (): ValidatorFn => (
  control: AbstractControl
): ValidationErrors | null => {
  const value = control.value;

  if (!value) {
    return null;
  }

  const emailValid = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-z]{2,}$/.test(
    value
  );

  return !emailValid ? { email: true } : null;
};

export const phoneValidator = (): ValidatorFn => (
  control: AbstractControl
): ValidationErrors | null => {
  const value = control.value;

  if (!value) {
    return null;
  }
  const phoneValid = /^[0-9+\-()\s]+$/.test(value);

  return !phoneValid ? { phone: true } : null;
};

export const numberValidator = (): ValidatorFn => (
  control: AbstractControl
): ValidationErrors | null => {
  const value = control.value;

  if (!value) {
    return null;
  }
  const numberValid = /^[0-9]+$/.test(value);

  return !numberValid ? { numberError: true } : null;
};

export const checkboxValidator = (): ValidatorFn => (
  control: AbstractControl
): ValidationErrors | null => {
  const value = control.value;

  if (!value) {
    return null;
  }

  return value.some(item => item === true) ? null : { checkboxError: true };
};

export const datepickerValidator = (): ValidatorFn => (
  control: AbstractControl
): ValidationErrors | null => {
  const value = control.value;

  return value === 'Invalid Date' ? { date: true } : null;
};

export const lengthValidator = (
  min = 0,
  max,
  type = 'characters'
): ValidatorFn => (control: AbstractControl): ValidationErrors | null => {
  const value = control.value;

  if (!value) {
    return null;
  }

  if (min && max && min === max && value.length !== min) {
    return { equal: { requiredLength: max, type } };
  }

  if (max && value.length > max) {
    return { maxLength: { requiredLength: max, type } };
  }

  if (min && value.length < min) {
    return { minLength: { requiredLength: min, type } };
  }

  return null;
};

export const emailDomainValidator = (): ValidatorFn => (
  control: AbstractControl
): ValidationErrors | null => {
  const value = control.value;

  if (!value) {
    return null;
  }

  const result = unavailableDomains.some(domain =>
    value.trim().endsWith(domain)
  );

  return result ? { email: true } : null;
};

const unavailableDomains = [
  '@aol.co',
  '@aol.col',
  '@aol.con',
  '@gail.com',
  '@gaiml.com',
  '@gamial.com',
  '@gamil.com',
  '@gamlie.com',
  '@gm.com',
  '@gma.com',
  '@gmael.com',
  '@gmai.com',
  '@gmai.coml',
  '@gmaik.com',
  '@gmail..com',
  '@gmail.cil',
  '@gmail.cim',
  '@gmail.clm',
  '@gmail.cm',
  '@gmail.co',
  '@gmail.cok',
  '@gmail.col',
  '@gmail.colm',
  '@gmail.con',
  '@gmail.cop',
  '@gmail.copm',
  '@gmail.cpm',
  '@gmail.net',
  '@gmail.ocm',
  '@gmail.om',
  '@gmail.org',
  '@gmail.ovm',
  '@gmail.vom',
  '@gmail.xm',
  '@gmail.xom',
  '@gmail.zom',
  '@gmaill.com',
  '@gmaio.com',
  '@gmaip.com',
  '@gmal.com',
  '@gmali.com',
  '@oitlook.com',
  '@ouitlook.com',
  '@outkook.com',
  '@outlok.com',
  '@outloo.com',
  '@outlook.con',
  '@outloook.com',
  '@outook.com',
  '@test.ca',
  '@test.com',
  '@test.io',
  '@test.me',
  '@test.test',
  '@tester.com',
  '@yahoo.ccom',
  '@yahoo.cim',
  '@yahoo.co',
  '@yahoo.cok',
  '@yahoo.con',
  '@yahoo.cpm',
  '@yahoo.net',
  '@yahoo.som',
  '@yahoo.vom',
  '@yahoo.xom',
  '@yahool.com',
  '@yahop.com',
  '@yahpo.com',
  '@yamhoo.com',
  '@yayoo.com',
  '@yhoo.com',
  '@ymai.com',
  '@ymail.con',
  '@ymsil.com',
  '@yo.com',
  '@yohoo.com',
  '@yshoo.com'
];
