<div
  mat-dialog-content
  class="p-3 content"
  [innerHtml]="data?.text"
>
</div>
<div
  class="py-3"
  mat-dialog-actions
  [align]="'center'"
>
  <lib-button
    [mat-dialog-close]="true"
    (click)="continueClick($event)"
  >
    Continue
  </lib-button>
</div>