import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  JobsProvider,
  SubscriptionProvider,
  PopularSearchesProvider
} from './data-providers';
import { NgxsModule } from '@ngxs/store';
import { JobsState } from './store/jobs/state';
import { environment } from '../environments/environment';
import { JobsStateFacade } from './store/jobs/facade';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {
  QueryParamsGuard,
  NotificationGuard,
  LocationService,
  AuthService
} from './services';
import { OverlayService } from './services/overlay.service';

@NgModule({ declarations: [], imports: [CommonModule,
        NgxsModule.forRoot([JobsState], {
            developmentMode: !environment.production
        })], providers: [JobsProvider, JobsStateFacade, LocationService, provideHttpClient(withInterceptorsFromDi())] })
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        JobsProvider,
        SubscriptionProvider,
        JobsStateFacade,
        LocationService,
        NotificationGuard,
        QueryParamsGuard,
        OverlayService,
        PopularSearchesProvider,
        AuthService
      ]
    };
  }
}
