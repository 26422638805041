
<div
  class="jobs-container"
  [class.jobs-container_loading]="loading"
>
  <div
    *ngIf="!loading && pagesInfo && pagesInfo.total > pagesInfo.perPage"
    class="top-pages-info mb-2"
  >
    Page {{pagesInfo.currentPage}} of {{pagesInfo.total}} jobs
  </div>
  <gc-native-ad
    [location]='location'
    [keyword]='keyword'
    *ngIf="showAd"
  ></gc-native-ad>
  <ng-template
    ngFor
    let-job
    [ngForOf]="jobs | paginate: {
      itemsPerPage: pagesInfo.perPage,
      currentPage: pagesInfo.currentPage,
      totalItems: pagesInfo.total }"
  >
    <gc-jobs-tile
      [job]="job"
      [class.selected]="job.id === jobSelectedId"
      (click)="jobClickHandler(job)">
    </gc-jobs-tile>
  </ng-template>


  <div class="no-content my-4" *ngIf="!jobs?.length && !loading">
    No jobs available. <br>Please modify your search to explore other jobs!
  </div>
</div>
<div class="page-loader" *ngIf="loading"></div>

<pagination-template
  #p="paginationApi"
  (pageChange)="setPage($event)"
>
<div
  class="pagination my-4 d-flex"
  *ngIf="p.pages && p.pages.length > 1"
>
  <div
    *ngIf="!p.isFirstPage()"
    [class.disabled]="loading"
    class="pagination-previous page mr-3"
  >
    <a (click)="p.previous()">
      « <span class="d-none d-sm-inline">Previous</span>
    </a>
  </div>
  <div
    class="page mr-4"
    *ngFor="let page of p.pages"
    [class.disabled]="loading"
    [class.current]="p.getCurrent() === page.value"
  >
    <a
      (click)="p.setCurrent(page.value)"
      *ngIf="p.getCurrent() !== page.value"
    >
      <span>{{ page.label }}</span>
    </a>
    <div *ngIf="p.getCurrent() === page.value">
      <span>{{ page.label }}</span>
    </div>
  </div>

  <div
    class="page pagination-next"
    [class.disabled]="p.isLastPage() || loading"
  >
    <a
      *ngIf="!p.isLastPage()"
      (click)="p.next()"
    ><span class="d-none d-sm-inline">Next</span> »</a>
  </div>
</div>
</pagination-template>
